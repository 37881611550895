import React from 'react'
import styled from '@emotion/styled'
import { Container, LeftHeader, Page, Button } from 'stylewhere/components'
import { Auth } from 'core/api'

export default function Login() {
  return (
    <Page title="Login" header={null}>
      <Container>
        <LeftHeader />
        <RightContainer>
          <Button title="Login" onClick={() => Auth.login()} />
        </RightContainer>
      </Container>
    </Page>
  )
}

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.padding};
  padding: ${({ theme }) => theme.padding};
  border-radius: ${({ theme }) => theme.padding};
  background-color: ${({ theme }) => theme.background2};
`
