import React, { Component } from 'react'
import { DecodedItem, GroupedItemsByProduct, ShippingParcel } from 'stylewhere/api'
import { GroupedItemList, GroupedProductList } from 'stylewhere/components'
import { OperationConfig, OperationReadingProvider } from 'stylewhere/shared'

interface Props {
  items: DecodedItem<string>[]
  operation: OperationConfig
  extension: any
  removeItemCallback?: (item: DecodedItem<string>) => void
  actionItemCallback?: (item: DecodedItem<string>) => void
  parcel?: ShippingParcel
}

export class OperationReadingList extends Component<Props> {
  render() {
    const { operation, parcel, removeItemCallback, actionItemCallback, items, extension } = this.props
    const rows = OperationReadingProvider.groupItems(items, operation, extension, parcel)

    if (operation.readingsDisplayMode === 'groupedByProduct') {
      return (
        <GroupedProductList
          operation={operation}
          rows={rows as GroupedItemsByProduct[]}
          onRemoveItem={removeItemCallback}
          onActionItem={actionItemCallback}
        />
      )
    }
    if (operation.readingsDisplayMode === 'item') {
      return (
        <GroupedItemList
          operation={operation}
          rows={rows as DecodedItem[]}
          onRemoveItem={removeItemCallback}
          onActionItem={actionItemCallback}
        />
      )
    }
    throw new Error(`Unsupported operation readingsDisplayMode ${operation.readingsDisplayMode}`)
  }
}
//OperationReadingRenderRows
