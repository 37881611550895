import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Image, Spacer } from 'stylewhere/components'
import { ProductInfoField, Products, TmrProduct } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { getField } from 'stylewhere/utils'

interface Props {
  product?: TmrProduct
  fields?: ProductInfoField[]
}

export class ProductInfoBox extends Component<Props> {
  static Field = ({ label, value = '---' }: { label?: string; value?: string }) => (
    <ContainerValue row>
      <LabelText>{label}</LabelText>
      <ValueText>{value}</ValueText>
    </ContainerValue>
  )

  getValue = (field: ProductInfoField) => {
    const { product } = this.props
    if (field.path) return getField(product, field.path)
    if (field.paths) return field.paths.map((path) => getField(product, path) as string).join(' - ')
    return '---'
  }

  render() {
    const { product, fields = [] } = this.props
    return (
      <CardContainer>
        <ProductBox flex>
          <Title>{__(T.misc.product)}</Title>
          <Box row>
            <Image transparent width={120} variant="boxed" src={Products.getImageUrl(product?.code)} />
            <Spacer />
            <Box flex>
              <CodeLabel>{product?.attributes?.barcodeBC?.value ?? '---'}</CodeLabel>
              <DescriptionLabel>{product?.description ?? '---'}</DescriptionLabel>
              <Spacer />
              {fields.map((field) => (
                <ProductInfoBox.Field label={field.label} value={this.getValue(field) ?? '---'} />
              ))}
            </Box>
          </Box>
        </ProductBox>
      </CardContainer>
    )
  }
}

const ContainerValue = styled(Box)`
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: 0 solid #e0e0e0;
  }
`

const CardContainer = styled(Box)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
`

const ProductBox = styled(Box)`
  padding: 20px 20px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.4;
  margin-bottom: 20px;
`

const LabelText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
`
const ValueText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
`

const CodeLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
`

const DescriptionLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`
