import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Image, Box, ProductItemsModal, GroupedListRow, RoundedLabel } from 'stylewhere/components'
import { DecodedItem, Products, TmrProduct, TmrStatus } from 'stylewhere/api'
import { isNumber } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig, ShippingOperationConfig } from 'stylewhere/shared'

interface Props {
  operation: OperationConfig | ShippingOperationConfig
  product: TmrProduct
  detected?: number
  expected?: number
  unexpected?: number
  hideCounter?: boolean
  noShadow?: boolean
  flex?: boolean
  status?: TmrStatus
  decodedItems: DecodedItem[]
  onRemoveItem?: (item: DecodedItem) => void
}

interface State {
  modalVisible: boolean
}

export class GroupedProductRow extends PureComponent<Props, State> {
  static Skeleton = styled(Box)``

  state: State = {
    modalVisible: false,
  }

  render() {
    const {
      product,
      detected,
      operation,
      expected,
      unexpected,
      decodedItems,
      hideCounter,
      noShadow,
      flex,
      status,
      onRemoveItem,
    } = this.props
    const read = (detected ?? 0) + (unexpected ?? 0)
    const hasChecklist = 'hasChecklist' in operation && operation.hasChecklist
    const counterValue = expected ? `${read}/${expected}` : `${read}${hasChecklist ? '/0' : ''}`
    const counterErrorItems = decodedItems.filter((item) => item.item?.status === 'error').length
    const counterWarningItems = decodedItems.filter((item) => item.item?.status === 'warning').length

    return (
      <>
        {this.state.modalVisible && (
          <ProductItemsModal
            onClose={() => this.setState({ modalVisible: false })}
            isOpen={this.state.modalVisible}
            product={product}
            decodedItems={decodedItems}
            onRemoveItem={onRemoveItem}
            operation={operation}
          />
        )}
        <GroupedListRow noShadow={noShadow} flex={flex} onClick={() => this.setState({ modalVisible: true })} clickable>
          {product.code && (
            <Box center p={8}>
              <Image width={115} height={120} src={Products.getImageUrl(product.code)} />
            </Box>
          )}
          <Box flex ph={16} style={{ alignSelf: 'center' }}>
            <Box mb={10}>
              <ProductCode>{product.code}</ProductCode>
              {product.description && <ProductDescription>{product.description}</ProductDescription>}
            </Box>

            <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              <RoundedLabel label={__(T.misc.size)} value={product.size?.value} />
              <RoundedLabel label={__(T.misc.color)} value={product.color?.value} />
            </Box>
          </Box>

          <Box center>
            {!hideCounter && (isNumber(detected) || isNumber(expected) || isNumber(unexpected)) && (
              <SmallCounter
                mr={30}
                ph={20}
                detected={detected}
                expected={expected}
                unexpected={unexpected}
                noChecklist={!hasChecklist}
                status={status}
              >
                {counterValue}
              </SmallCounter>
            )}
            {counterErrorItems > 0 && (
              <Box
                center
                pv={5}
                mt={5}
                ph={5}
                mh={5}
                style={{ fontWeight: 'bold', width: '100%', backgroundColor: '#FF8474', borderRadius: 5 }}
              >
                ERROR ({counterErrorItems})
              </Box>
            )}
            {counterWarningItems > 0 && (
              <Box
                center
                pv={5}
                mt={5}
                mh={5}
                ph={5}
                style={{ fontWeight: 'bold', width: '100%', backgroundColor: '#FFEA67', borderRadius: 5 }}
              >
                WARNING ({counterWarningItems})
              </Box>
            )}
          </Box>
        </GroupedListRow>
      </>
    )
  }
}

const ProductDescription = styled.label`
  font-size: 15px;
  word-break: break-word;
`
const ProductCode = styled.label`
  font-weight: bold;
  font-size: 20pt;
  word-break: break-word;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
  status?: TmrStatus
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false, status }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`
