import React, { useState, useRef, useEffect } from 'react'
import ReactSelect, { components, OptionsType } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useDebouncedCallback } from 'use-debounce'
import { Box, Icons, Modal } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { getField } from 'stylewhere/utils'

type OptionConfig = { label: string; value: string; secondaryLabel?: string }

interface SelectProps {
  placeholder?: React.ReactNode
  defaultValue?: any
  containerStyle?: React.CSSProperties
  controlStyle?: React.CSSProperties
  disabled?: boolean
  options?: any[]
  asyncOptions?: (inputValue: string, callback: (options: OptionsType<Record<string, any>>) => void) => void
  debounce?: boolean
  config: OptionConfig
  borderColor?: string
  onSelect: (item?: any) => void
  transparent?: boolean
  multiple?: boolean
  value?: any
}

const Option: typeof components.Option = ({ children, ...props }) => (
  <components.Option {...props}>
    <Box flex row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <span
        style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        title={children?.toString()}
      >
        {children}
      </span>
      {props.isSelected && <Icons.CheckFilled />}
    </Box>
  </components.Option>
)

const ClearIndicator: typeof components.ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Icons.Close />
  </components.ClearIndicator>
)

export const Select: React.FC<SelectProps> = ({
  onSelect,
  config,
  placeholder,
  defaultValue,
  containerStyle,
  controlStyle,
  options,
  asyncOptions,
  debounce = true,
  borderColor = '#D2D2D2',
  disabled,
  transparent,
  multiple = false,
  value,
}) => {
  const selectRef = useRef<ReactSelect<OptionConfig>>(null)
  const [open, setOpen] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const debouncedAsyncOptions = useDebouncedCallback(asyncOptions ?? (() => {}), 400, { leading: true })

  useEffect(() => {
    setFirstRender(false)
  }, [])

  const styles = {
    container: (base) => ({
      ...base,
      border: 0,
      ...containerStyle,
    }),
    control: (base, state) => ({
      ...base,
      height: 65,
      fontSize: 22,
      paddingLeft: 10,
      backgroundColor: transparent ? 'transparent' : '#ffffff',
      boxShadow: state.isFocused ? '0 0 0 3px rgba(47, 128, 237, 0.5)' : 'none',
      borderWidth: 2,
      borderColor: state.isFocused ? '#2f80ed' : borderColor,
      borderRadius: 10,
      '&:hover': {
        borderColor: state.isFocused ? '#2f80ed' : borderColor,
      },
      ...controlStyle,
    }),
    input: () => ({
      color: '#333333',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#666666',
    }),
    menu: (base) => ({
      ...base,
      position: 'relative',
      border: 0,
      boxShadow: 0,
      marginTop: '1rem',
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 350,
    }),
    option: (base, state) => ({
      ...base,
      fontWeight: 500,
      fontSize: 22,
      lineHeight: '48px',
      borderRadius: 10,
      height: 70,
      color: '#333333',
      borderWidth: 2,
      borderColor: state.isSelected ? '#000000' : '#F5F5F5',
      padding: '10px 20px',
      backgroundColor: '#F5F5F5',
      marginBottom: '1rem',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'black',
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: (base) => ({
      ...base,
      paddingRight: 10,
      '& svg': { width: 26, height: 26 },
    }),
    clearIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
  }

  const closeModal = () => {
    setOpen(false)
  }

  const onChange = (item) => {
    onSelect(item)
    closeModal()
  }

  const getOptionLabel = (option: Record<string, any>) => {
    const label = getField(option, config.label)
    if (label) return label
    return config.secondaryLabel ? getField(option, config.secondaryLabel) : ''
  }

  const modalSelectProps = {
    isClearable: true,
    isMulti: multiple,
    isSearchable: true,
    styles,
    getOptionLabel,
    getOptionValue: (option) => getField(option, config.value),
    defaultValue: firstRender ? defaultValue : value,
    onChange,
    value,
    autoFocus: true,
    menuIsOpen: true,
    placeholder: __(T.misc.select_search),
    components: {
      ClearIndicator,
      DropdownIndicator: null,
      Option,
    },
  }

  return (
    <>
      <Modal visible={open} onClose={closeModal} title={placeholder} size="xl">
        <div style={{ minHeight: 435 }}>
          {asyncOptions && (
            <AsyncSelect
              {...modalSelectProps}
              loadOptions={debounce ? debouncedAsyncOptions : asyncOptions}
              defaultOptions
              cacheOptions
              loadingMessage={() => null}
            />
          )}
          {!asyncOptions && <ReactSelect {...modalSelectProps} options={options} />}
        </div>
      </Modal>
      <ReactSelect<OptionConfig>
        ref={selectRef}
        styles={{
          ...styles,
          container: (base, state) => ({
            ...base,
            border: `2px solid ${state.isDisabled ? '#eeeeee' : borderColor}`,
            borderRadius: 10,
          }),
          control: (base) => ({
            ...base,
            height: 65,
            fontSize: 22,
            paddingLeft: 10,
            backgroundColor: transparent ? 'transparent' : '#ffffff',
            boxShadow: 'none',
            borderWidth: 0,
            borderRadius: 10,
            cursor: 'pointer',
            ...controlStyle,
          }),
          placeholder: (base, state) => ({
            ...base,
            color: state.isDisabled ? '#eeeeee' : '#666666',
          }),
          input: (base) => {
            return {
              ...base,
              '& input': {
                cursor: 'pointer',
              },
            }
          },
        }}
        placeholder={placeholder}
        value={firstRender ? defaultValue : value}
        options={value ? [value] : []}
        getOptionLabel={getOptionLabel}
        onMenuOpen={() => setOpen(true)}
        onChange={() => onSelect()}
        components={value ? { ClearIndicator, DropdownIndicator: null } : { ClearIndicator }}
        isDisabled={disabled}
      />
    </>
  )
}
