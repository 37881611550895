import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'

export const GroupedListRow = styled(Box)<{
  noShadow?: boolean
  clickable?: boolean
  backgroundColor?: string
}>`
  flex-direction: row;
  margin-bottom: 20px;
  padding: 10px 0px;
  border: 2px solid #edecec;
  box-shadow: ${({ noShadow = false }) => {
    if (noShadow) return 'none'
    return '0 1px 5px rgba(0, 0, 0, 0.08)'
  }};
  border-radius: 10px;
  background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.background2};

  ${({ clickable }) =>
    clickable &&
    `  
      & * {
        cursor: pointer;
      }
  `}
`
