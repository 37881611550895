import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Icons, Input, StyledInput, Select, Box, DatePicker, Switch, Spacer, TextArea } from 'stylewhere/components'
import {
  AutocompleteFormSchemaField,
  CheckboxFormSchemaField,
  DateFormSchemaField,
  FormSchemaField,
  NumberFormSchemaField,
  SelectFormSchemaField,
  TextFormSchemaField,
  setArgsParams,
} from 'stylewhere/shared'
import { api, BaseResource, ResourcePage } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

interface Props<Value = any> {
  initialValue?: any
  disabled?: boolean
  field: FormSchemaField
  index: number
  value: Value
  defaultValue: Value
  onChange: (value: any) => void
  formContext?: string
}

const Label = styled.label`
  font-weight: 600;
  font-size: 10pt;
`

export const FormSchemaFieldLabel: React.FC<{ label: string; required?: boolean }> = ({ label, required }) => {
  if (required)
    return (
      <span>
        {label} <sup>*</sup>
      </span>
    )
  return <span>{label}</span>
}

export const FormSchemaTextareaField: React.FC<
  Props & {
    field: TextFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange }) => {
  return (
    <>
      <Label key={`label${index.toString()}`} htmlFor={field.name}>
        <FormSchemaFieldLabel label={field.label} required={field.required} />
      </Label>
      <TextArea
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        required={field.required}
        id={field.name}
      />
    </>
  )
}

const CheckboxWrapper = styled(Box)`
  height: 65px;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  padding-left: 22px;
  padding-right: 22px;
  margin-top: 20px;

  :disabled {
    border: 2px solid transparent;
    color: #000000;
  }
`

export const FormSchemaCheckboxField: React.FC<
  Props & {
    field: CheckboxFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  return (
    <CheckboxWrapper key={index.toString()} row>
      <Switch
        onChange={(checked) => {
          if (field.onValue !== undefined && checked) {
            onChange(field.onValue)
          } else if (field.offValue !== undefined && !checked) {
            onChange(field.offValue)
          } else {
            onChange(checked)
          }
        }}
        check={value ?? defaultValue}
        id={field.name}
        name={field.name}
        label={field.label}
        labelPosition="right"
        wrapperProps={{
          justify: 'space-between',
          width: '100%',
        }}
        disabled={disabled ?? field.disabled}
      />
    </CheckboxWrapper>
  )
}

export const FormSchemaDateField: React.FC<
  Props & {
    field: DateFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  const dateValue = value === undefined ? defaultValue : value

  let startDate
  let endDate
  let selected
  if (field.range) {
    startDate = dateValue && dateValue[field.startDatePath] && new Date(dateValue[field.startDatePath])
    endDate = dateValue && dateValue[field.endDatePath] && new Date(dateValue[field.endDatePath])
  } else {
    selected = dateValue && new Date(dateValue)
  }

  const DateInput = forwardRef<HTMLInputElement>((props, ref) => <StyledInput error={false} {...props} ref={ref} />)

  let placeholderText
  if (!selected) {
    placeholderText = field.required ? `${field.label} *` : field.label
  }

  return (
    <>
      <Label key={`label${index.toString()}`} htmlFor={field.name}>
        {selected ? <FormSchemaFieldLabel label={field.label} required={field.required} /> : <>&nbsp;</>}
      </Label>
      {!field.range && (
        <DatePicker
          name={field.name}
          selected={selected}
          showTimeSelect={field.time}
          onChange={(date: Date | null) => onChange(date && date.toISOString())}
          required={field.required}
          isClearable
          disabled={disabled ?? field.disabled}
          customInput={<DateInput />}
          placeholderText={placeholderText}
        />
      )}
      {field.range && (
        <Box flex row>
          <Box mr={5} style={{ flexShrink: 1 }}>
            <DatePicker
              name={`${field.name}-start`}
              placeholderText={__(T.misc.from)}
              showTimeSelect={field.time}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={(date: Date | null) =>
                onChange({
                  [field.startDatePath]: date && date.toISOString(),
                  [field.endDatePath]: endDate,
                })
              }
              required={field.required}
              disabled={disabled ?? field.disabled}
              isClearable
              customInput={<DateInput />}
            />
          </Box>
          <Box ml={5} style={{ flexShrink: 1 }}>
            <DatePicker
              name={`${field.name}-end`}
              placeholderText={__(T.misc.to)}
              showTimeSelect={field.time}
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              onChange={(date: Date | null) =>
                onChange({
                  [field.startDatePath]: startDate,
                  [field.endDatePath]: date && date.toISOString(),
                })
              }
              required={field.required}
              disabled={disabled ?? field.disabled}
              isClearable
              customInput={<DateInput />}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export const FormSchemaAutocompleteField: React.FC<
  Props & {
    field: AutocompleteFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled, formContext }) => {
  const { endpoint } = field

  const asyncOptions = (search: string, callback: (results: BaseResource[]) => void) => {
    ;(async () => {
      search = search.trim()
      const params = search ? { search } : { size: 25 }
      // Usiamo il callback invece di restituire la promise sennò react-select
      // non si aggiorna corretamente
      callback((await api.get<ResourcePage<BaseResource>>(setArgsParams(endpoint), params)).data?.content ?? [])
    })()
  }

  if (field.readOnly === true || (formContext !== undefined && field.readOnly === formContext)) {
    return (
      <FormSchemaValueField
        index={index}
        field={field}
        defaultValue={defaultValue?.description ?? defaultValue?.code ?? ''}
        formContext={formContext}
      />
    )
  }

  return (
    <>
      {value && (
        <Label key={`label${index.toString()}`} htmlFor={field.name}>
          <FormSchemaFieldLabel label={field.label} required={field.required} />
        </Label>
      )}
      {!value && <Spacer />}
      <Select
        onSelect={onChange}
        placeholder={<FormSchemaFieldLabel label={field.label} required={field.required} />}
        asyncOptions={asyncOptions}
        config={{
          value: 'id',
          label: field.optionLabel ?? 'description',
          secondaryLabel: field.optionSecondaryLabel ?? 'code',
        }}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled ?? field.disabled}
      />
    </>
  )
}

export const FormSchemaSelectField: React.FC<
  Props & {
    field: SelectFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  return (
    <>
      <Label key={`label${index.toString()}`} htmlFor={field.name}>
        {value ? <FormSchemaFieldLabel label={field.label} required={field.required} /> : <>&nbsp;</>}
      </Label>
      <Select
        onSelect={onChange}
        placeholder={<FormSchemaFieldLabel label={field.label} required={field.required} />}
        options={field.options}
        config={{ value: 'value', label: 'label' }}
        defaultValue={defaultValue}
        multiple={field.multiple}
        value={value}
        disabled={disabled ?? field.disabled}
      />
    </>
  )
}

export const FormSchemaTextField: React.FC<
  Props & {
    field: TextFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  const Icon = field.icon && Icons[field.icon]
  return (
    <>
      <Spacer />
      <Input
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        required={field.required ?? false}
        onChange={onChange}
        value={value}
        id={field.name}
        type={field.type}
        placeholder={field.secondaryLabel}
        startFocus={field.focus}
        image={Icon && <Icon />}
        label={field.label}
        disabled={disabled ?? field.disabled}
        minLength={field.minlength}
        maxLength={field.maxlength}
      />
    </>
  )
}

export const FormSchemaNumberField: React.FC<
  Props & {
    field: NumberFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  const Icon = field.icon && Icons[field.icon]
  return (
    <>
      <Label>&nbsp;</Label>
      <Input
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        required={field.required ?? false}
        onChange={(itm) => onChange(itm)}
        value={value}
        id={field.name}
        type="number"
        placeholder={field.secondaryLabel}
        startFocus={field.focus}
        image={Icon && <Icon />}
        label={<FormSchemaFieldLabel label={field.label} required={field.required} />}
        disabled={disabled ?? field.disabled}
        min={field.min?.toString()}
        max={field.max?.toString()}
        step={field.step?.toString()}
      />
    </>
  )
}

export const FormSchemaValueField: React.FC<
  Omit<Props, 'onChange' | 'value'> & {
    field: FormSchemaField
  }
> = ({ index, field, defaultValue, formContext }) => {
  return (
    <>
      <Label>&nbsp;</Label>
      <Input
        key={`input${index.toString()}`}
        value={defaultValue}
        defaultValue={defaultValue}
        id={field.name}
        type="text"
        placeholder={field.secondaryLabel}
        label={field.label}
        readOnly={field.readOnly === true || (formContext !== undefined && field.readOnly === formContext)}
        showClear={false}
      />
    </>
  )
}

/**
 * Example
 * schema={[
        { placeholder: __(T.misc.username), name: 'username', required: true },
        { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
    ]}
 */
export const FormSchemaFormField: React.FC<Props> = ({ field, ...props }) => {
  if (field.customRender) {
    return field.customRender({ field, ...props })
  }
  switch (field.type) {
    case 'textarea':
      return <FormSchemaTextareaField field={field} {...props} />
    case 'checkbox':
      return <FormSchemaCheckboxField field={field} {...props} />
    case 'date':
      return <FormSchemaDateField field={field} {...props} />
    case 'autocomplete':
      return <FormSchemaAutocompleteField field={field} {...props} />
    case 'select':
      return <FormSchemaSelectField field={field} {...props} />
    case 'text':
    case 'password':
      return <FormSchemaTextField field={field} {...props} />
    case 'number':
      return <FormSchemaNumberField field={field} {...props} />
    case 'value':
      return <FormSchemaValueField field={field} {...props} />
    default:
      return null
  }
}
