import React, { Component } from 'react'
import styled from '@emotion/styled'
import {
  Page,
  LeftHeader,
  KeyValueRow,
  Spacer,
  Box,
  UserInfoModal,
  SettingsModal,
  Image,
  Icons,
} from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { TmrWorkstation } from 'stylewhere/api'
import { showToast } from 'stylewhere/utils'
import { AppStore, Router, RemoteOperation, DashboardButtonProps, getDashboardMenuButtons } from 'stylewhere/shared'

interface State {
  userModalVisible: boolean
  settingsModalVisible: boolean
}
export default class Dashboard extends Component<{}, State> {
  state: State = {
    userModalVisible: false,
    settingsModalVisible: false,
  }

  componentDidMount() {
    //Refresh operations on every dashboard load
    RemoteOperation.load()
  }

  setuserModalVisible = (userModalVisible: boolean) => this.setState({ userModalVisible })

  setsettingsModalVisible = (settingsModalVisible: boolean) => this.setState({ settingsModalVisible })

  selectDefaultWorkstation = async (workstation: TmrWorkstation) => {
    await AppStore.saveDefaultWorkstation(workstation)
    Router.refresh()
  }

  render() {
    const { userModalVisible, settingsModalVisible } = this.state

    const operations = getDashboardMenuButtons()

    return (
      <Page title="Dashboard" header={null}>
        <Box flex row>
          <LeftHeader>
            <KeyValueRow
              onClick={() => this.setuserModalVisible(!userModalVisible)}
              label={__(T.misc.user)}
              value={AppStore.loggedUser?.username}
              image={<Icons.Info />}
            />
            <Spacer />
            <KeyValueRow
              label={__(T.misc.workstation)}
              value={AppStore.defaultWorkstation?.code ?? '---'}
              optionModal={{
                onSelect: (item) => this.selectDefaultWorkstation(item[0]),
                field: 'code',
                options: AppStore.workstations ?? [],
                selected: [AppStore.defaultWorkstation],
                title: __(T.titles.select_workstation),
                searchable: true,
              }}
            />
          </LeftHeader>
          <RightContainer>
            <DashView>
              {operations.map((operation) => (
                <DashboardButton key={operation.code} {...operation} />
              ))}

              {operations.length % 2 === 1 && <DashboardFakeButton />}
            </DashView>
          </RightContainer>
        </Box>
        <UserInfoModal
          user={AppStore.loggedUser}
          onClose={() => this.setuserModalVisible(false)}
          visible={userModalVisible}
        />
        <SettingsModal onClose={() => this.setsettingsModalVisible(false)} visible={settingsModalVisible} />
      </Page>
    )
  }
}

const DashboardButton: React.FC<DashboardButtonProps> = ({ operation, routeParams, icon, text }) => {
  const onClick = () => {
    if (!AppStore.defaultWorkstation) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      if (!AppStore.emulation) return
    }
    operation && Router.navigate(operation, routeParams)
  }
  return (
    <DashboardItemStyle onClick={onClick}>
      {icon && typeof icon !== 'string' && icon}
      {icon && typeof icon === 'string' && <DashImage alt={text} src={icon} />}
      {text}
    </DashboardItemStyle>
  )
}

const DashboardItemStyle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  width: 276px;
  height: 202px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 10px;
  margin: 0 20px 30px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const DashboardFakeButton = styled.div`
  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0;
`

const DashImage = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`

const DashView = styled(Box)`
  max-width: 700px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 50px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const RightContainer = styled.div`
  display: flex;
  flex: 1.5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
