import React, { Component } from 'react'
import { RfidReader } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Box, Icons, Spacer } from 'stylewhere/components'
import { DecodedItem, TmrTag } from 'stylewhere/api'
import { animations } from 'stylewhere/assets'
import Lottie from 'react-lottie'

interface Props {
  onStart?: () => void
  onItemDecoded?: (items: { [epc: string]: DecodedItem }, tags?: TmrTag[]) => void
  onClear?: () => void
  onError?: () => void
  icon?: string
  content?: JSX.Element
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  leftBottom?: boolean
  hideClear?: boolean
}

interface State {
  reading: boolean
  starting: boolean
  pendingTags: number
}

export class AntennaButton extends Component<Props, State> {
  static defaultProps = {
    leftBottom: false,
  }

  state: State = {
    reading: false,
    starting: false,
    pendingTags: 0,
  }

  constructor(props: Props) {
    super(props)
    RfidReader.initialize()
    RfidReader.onStartCallbackAntennaButton = this.onStartCallback
    RfidReader.onStopCallbackAntennaButton = this.onStopCallback
    RfidReader.onDecodedItemCallback = this.props.onItemDecoded
    RfidReader.onPendingTagsChangeAntennaButton = (pendingTags: number) => this.setState({ pendingTags })
  }

  componentWillUnmount() {
    this.stop()
    RfidReader.clear()
  }

  async start(): Promise<boolean> {
    if (RfidReader.isReading()) return false
    const { onError, onStart } = this.props
    this.setState({ starting: true })
    onStart?.()
    const result = await RfidReader.start(undefined, undefined, onError)
    this.setState({ starting: false })
    return result
  }

  async stop(): Promise<boolean> {
    return RfidReader.isReading() ? RfidReader.stop() : false
  }

  onStartCallback = () => this.setState({ reading: true })

  onStopCallback = () => this.setState({ reading: false })

  onClickAntennaButton = () => {
    RfidReader.isReading() ? this.stop() : this.start()
  }

  onClear = () => {
    RfidReader.clear()
    this.props.onClear?.()
  }

  renderIcon = () => {
    const { starting, reading } = this.state
    if (reading) {
      return <Icons.Pause />
    }
    const style = {
      width: 28,
      height: 28,
    }
    if (starting) {
      return <Icons.Loader style={style} />
    }

    return <Icons.Play style={style} />
  }

  render() {
    const { style, buttonStyle, onClear, hideClear } = this.props
    const { reading, pendingTags } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animations.Onde,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    return (
      <>
        <Container row style={style}>
          <ButtonControl
            style={{ ...buttonStyle }}
            reading={reading}
            row
            center
            onClick={() => this.onClickAntennaButton()}
          >
            {this.renderIcon()}
            {!reading && (
              <Box flex center>
                {__(T.misc.start)}
              </Box>
            )}
          </ButtonControl>
          {reading && (
            <div>
              <Lottie isClickToPauseDisabled style={{ width: '100%', height: '100%' }} options={defaultOptions} />
            </div>
          )}
          {!hideClear && onClear && !reading && (
            <>
              <Spacer style={{ height: 5, width: 5, minHeight: 5, minWidth: 5 }} />
              <ButtonClear style={buttonStyle} row center onClick={this.onClear || undefined}>
                <Icons.Retry
                  style={{
                    width: 28,
                    height: 28,
                  }}
                />
                <Box flex center>
                  {__(T.misc.clear)}
                </Box>
              </ButtonClear>
            </>
          )}
        </Container>
        {pendingTags > 0 && (
          <PendingContainer row>
            Pending tags <div style={{ fontWeight: 900 }}>{pendingTags}</div>
          </PendingContainer>
        )}
      </>
    )
  }
}

const PendingContainer = styled(Box)`
  margin-top: 15px;
  background: #e4e4e4;
  border-radius: 50px;
  padding: 10px 22px;
  font-weight: 500;
  font-size: 18px;
  justify-content: space-between;
`

const Container = styled(Box)`
  background-color: white;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 50px;
  padding: 7px;
`

const ButtonControl = styled(Box)<{ reading?: boolean }>`
  flex: 1;
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 -1px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  height: 70px;
  min-width: 70px;
  cursor: pointer;
  font-weight: 900;
  padding: 0 20px;
  font-size: 22px;
  position: relative;
  background-color: ${(props) => (props.reading ? '#525252' : '#ceff00')};
  margin-right: ${({ reading }) => (reading ? '5px' : '0')};
`

const ButtonClear = styled(ButtonControl)`
  background-color: #e9e9e9;
`
