import { api, TmrBaseResource, responseErrorCheck, DecodedParcel } from 'stylewhere/api'

export class Parcels extends TmrBaseResource {
  static endpoint = 'logistics/api/v1/parcels'

  static async decode(decodePayload: Record<string, any>) {
    const res = await api.post<{ [epc: string]: DecodedParcel }>(
      `logistics/api/v1/operations/simpleParcelList/batchDecodeParcelList`,
      decodePayload
    )
    return responseErrorCheck(res)
  }
}
