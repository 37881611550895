import { ApisauceConfig } from 'apisauce'
import { api, TmrBaseResource, TmrItem, TmrTransfer, responseErrorCheck } from 'stylewhere/api'

export class Items extends TmrBaseResource {
  static endpoint = 'tracking/api/v1/items'

  static batchDecode<T>(epcs: string[], requestConfig?: ApisauceConfig) {
    return api
      .post<T>(`${Items.endpoint}/decode`, epcs, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static decode(
    decodedUrl: string,
    body: { identifierCodes: string[]; operationId: string },
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<any>(decodedUrl, body, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getTransfers(item: TmrItem) {
    if (!item.id) return []
    return (
      api
        // .get<TmrTransfer[]>(`/transfers?asc=false&item.epc=${item.epc}&limit=25&offset=0&orderBy=CREATION_DATE`)
        .get<TmrTransfer[]>(`/transfers?asc=false&limit=25&offset=0&orderBy=CREATION_DATE`)
        .then(responseErrorCheck)
        .then((res) => res)
    )
  }
}
