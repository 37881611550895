export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'Info Utente',
    settings: 'Impostazioni',
    reset_daily_items: 'Reset items giornalieri?',
    last_parcels: 'Ultimi colli',
    select_item: 'Seleziona item',
    select_workstation: 'Seleziona postazione',
    select_language: 'Seleziona lingua',
    support: 'Supporto',
    select_printer: 'Seleziona stampante',
    shipment_to_confirm: 'Spedizioni da confermare',
  },
  fields: {
    current_location: 'Posizione Attuale',
    identified_item: 'Item Identificato',
    ignored_items: 'Item ignorati',
    error_items: 'Item in errore',
    warning_items: 'Item in warning',
  },
  misc: {
    save: 'Salva',
    confirm: 'Conferma',
    cancel: 'Annulla',
    close: 'Chiudi',
    user: 'Utente',
    workstation: 'Postazione',
    username: 'Username',
    name: 'Nome',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    ean_code: 'Codice EAN',
    certilogo: 'Certilogo',
    production_order: 'Ordine di produzione',
    enter_the_necessary_fields: 'Inserisci i campi necessari per iniziare l’operazione',
    associated_items: 'Item associati',
    associate: 'Associa',
    verify: 'Verifica',
    reading: 'In lettura',
    association: 'Associazione',
    waiting: 'In attesa',
    writing: 'Scrittura',
    retry: 'Riprova',
    writing_association: 'Scrittura e Associazione',
    confirmed: 'Confermato',
    unable_to_write: `Impossibile scrivere il tag: {{code}} per il prodotto: {{productCode}}`,
    unable_to_get_next_epc: `Impossibile ottenere il prossimo epc del prodotto: {{productCode}}`,
    unable_to_connect_to_workstation: 'Impossibile connettersi alla postazione',
    tag_info: 'Tag Info',
    stop: 'Ferma',
    start: 'Avvia',
    pause: 'Pausa',
    clear: 'Svuota',
    ready_to_receive: 'Pronto per ricezione',
    change_password: 'Cambia password',
    logout: 'Effettua logout',
    are_you_sure_to_logout: 'Vuoi procedere con il logout?',
    settings: 'Impostazioni',
    roles: 'Ruoli',
    email: 'Email',
    place: 'Luogo',
    items: 'Item',
    description: 'Descrizione',
    color: 'Colore',
    size: 'Taglia',
    total_reads: 'LETTURE TOTALI',
    save_to_list: 'Salva nella lista',
    destination: 'Destinazione',
    origin: 'Origine',
    new_parcel: 'Nuovo Collo',
    status: 'Stato',
    parcel: 'Collo',
    parcels: 'Colli',
    shipment: 'Spedizione',
    creation_date: 'Data Creazione',
    search: 'Cerca',
    select_search: 'Cerca...',
    detail: 'Dettaglio',
    quantity: 'Quantità ({{quantity}})',
    style: 'Modello',
    material: 'Materiale',
    shipment_code_auto: 'Codice Spedizione (auto)',
    parcel_code_auto: 'Codice Collo (auto)',
    next: 'Avanti',
    parcels_to_receive: 'Colli da ricevere',
    confirm_parcel: 'Conferma Collo',
    image_missing: 'IMMAGINE MANCANTE',
    loading: 'Caricamento...',
    unknown_product: 'Prodotto sconosciuto',
    unknown_tag: 'Tag sconosciuto',
    invalid_tag: 'Tag non valido',
    stock: 'Stock',
    valid: 'Valido',
    unknown: 'Unknown',
    actions: 'Azioni',
    reprint: 'Ristampa',
    product: 'Prodotto',
    product_items: 'Item del Prodotto',
    item_identifiers: 'Identificatori',
    zone: 'Zona',
    report_repair: 'Segnala Riparazione',
    reason: 'Motivazione',
    request_code_change: 'Richiedi Cambio Codice',
    enable_workstation_emulation: 'Abilita emulazione Workstation',
    filter: 'Filtro',
    language: 'Lingua',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Opzionale',
    phone: 'Telefono',
    version: 'Versione',
    printer: 'Stampante',
    new_shipment: 'Nuova Spedizione',
    upc: 'UPC',
    model_part_color_size: 'Modello / Parte / Colore / Taglia',
    confirm_shipment: 'Conferma Spedizione',
    receive: 'Ricevi',
    delete_association: 'Elimina Associazione',
    change: 'Cambia',
    from: 'Da',
    to: 'A',
    ignored: 'Ignorato',
    yes: 'Sì',
    no: 'No',
    model: 'Modello',
    typology: 'Tipologia',
  },
  error: {
    error: 'Errore',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Hai letto troppi tag (attesi {{exptected}} letti {{read}})',
    too_many_checklist_types:
      'Più di un tipo di checklist trovato. Assicurati di selezionare colli con checklist dello stesso tipo.',
    certilogo_not_found: 'Certilogo "{{code}}" non trovato',
    ean_not_found: 'EAN "{{code}}" non trovato',
    production_order_not_found: 'Ordine di Produzione "{{code}}" non trovato',
    no_inbound_zones: 'Nessuna zona di Inbound',
    no_operation_config: 'Configurazione operazione non trovata',
    not_supported_reading_mode: 'Modalità di lettura non supportata',
    undefined_operation_config: 'La configurazione non è definita',
    certilogo_already_encoded: 'Certilogo "{{code}}" già encodato',
    tag_not_found: 'Tag non trovato',
    tag_unassociated: 'Tag non associato',
    tag_type_unexpected: 'Non è previsto un tag di questo tipo',
    tag_already_associated: 'Il tag è già associato ad un altro item',
    tags_not_associated: 'Uno o più tag non è associato ad alcun item',
    tags_mismatch: 'Il tag è associato ad un altro item',
    workstation_could_not_be_started: 'La workstation {{code}} non può essere avviata',
    authentication_failed: 'Autenticazione fallita',
    field_required: 'Il campo {{label}} è obbligatorio',
    unable_to_connect_to_rfid_reader: 'Impossibile avviare il reader. Verifica che il reader sia acceso',
    items_in_error_found: 'Trovati prodotti in errore',
    item_not_in_inbound: 'Prodotto non in inbound',
    item_not_in_stock: 'Prodotto non a stock',
    item_in_another_parcel: 'Prodotto in un altro collo',
    item_in_another_production_order: 'Prodotto in un altro ordine di produzione',
    connection: 'Errore di connessione',
    connection_details:
      'Il server non ha risposto. Controlla che la tua connessione internet e le eventuali configurazioni della tua VPN siano funzionanti.',
    field_invalid_default: 'Il valore predefinito "{{defaultValue}}" del campo {{field}} non è valido',
  },
  messages: {
    no_workstations_found: 'Nessuna Postazione trovata',
    no_workstation_selected: 'Nessuna Postazione selezionata',
    are_you_sure_to_reset_daily_items: 'Sei sicuro di voler resettare gli item giornalieri?',
    bring_an_item_to_antenna: "Avvicina un prodotto all'antenna",
    press_start_to_read: 'Premi Start per iniziare \na leggere i prodotti',
    paste_comma_separeted_tags: 'Incolla i tags separati da virgola e premi Enter...',
    connection_workstation_progress: 'Connessione alle antenne in corso...',
    operation_success: 'Operazione completata con successo',
    outbound_success: 'Outbound completata con successo',
    inbound_success: 'Inbound completata con successo',
    pack_success: 'Packing completata con successo',
    transfer_to_zone_success: 'Trasferimento a Zona completato con successo',
    list_empty: 'Lista vuota',
    looks_like_anything_in_list: 'Sembra che la tua lista sia vuota',
    generic_success: '{{code}} completato con successo',
    generic_success_canceled: '{{code}} annullata con successo',
    generic_error: '{{code}} in errore',
  },
  placeholder: {
    search_shipment_parcel: 'Cerca Commessa o Collo',
    search_product_parcel: 'Cerca Prodotto o Collo',
  },
  confirm: {
    discrepancy_title: 'Collo con discrepanze',
    discrepancy_message: 'Trovate discrepanze nel collo. Sei sicuro di voler confermare?',
    confirm_delete_association: 'Sei sicuro di voler disassociare questo prodotto?',
  },
  labels: {
    draft: 'Bozza',
    ready_to_send: 'Pronto per spedizione',
    send_await: 'In attesa',
    outbound_error: 'Errore Outbound',
    sent: 'Inviato',
    in_inbound: 'In Inbound',
    receive_await: 'In attesa',
    ready_to_receive: 'Da ricevere',
    inbound_error: 'Errore Inbound',
    received: 'Ricevuto',
    in_transit: 'In transito',
  },
}
