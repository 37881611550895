import React from 'react'
import styled from '@emotion/styled'
import { Box, Modal, GroupedItemList } from 'stylewhere/components'
import { DecodedItem } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig } from 'stylewhere/shared'

interface Props {
  title?: string
  isOpen: boolean
  decodedItems: DecodedItem[]
  operation?: OperationConfig
  onClose: () => void
}

export const DecodedItemsModal: React.FC<Props> = ({
  title = __(T.misc.product_items),
  isOpen,
  onClose,
  decodedItems,
  operation,
}) => {
  if (!isOpen) return null

  return (
    <Modal visible={isOpen} onClose={onClose} size="2xl" isCentered>
      <Box height="70vh">
        <Title>{title}</Title>
        <Box flex>
          <GroupedItemList operation={operation} rows={decodedItems} />
        </Box>
      </Box>
    </Modal>
  )
}

const Title = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 25px;
`
