import { ReactComponent as Barcode } from 'core/assets/img/icon-barcode.svg'
import { ReactComponent as Play } from 'core/assets/img/icon-play.svg'
import { ReactComponent as AnimatedBar } from 'core/assets/img/animated-bar.svg'
import { ReactComponent as Encoding } from 'core/assets/img/icon-encoding.svg'
import { ReactComponent as Retry } from 'core/assets/img/icon-retry.svg'
import { ReactComponent as Nfc } from 'core/assets/img/nfc-icon.svg'
import { ReactComponent as Antenna } from 'core/assets/img/antenna-icon.svg'
import { ReactComponent as Fullscreen } from 'core/assets/img/icon-fullscreen.svg'
import { ReactComponent as Search } from 'core/assets/img/icon-search.svg'
import { ReactComponent as AntennaOn } from 'core/assets/img/antenna-on-icon.svg'
import { ReactComponent as Settings } from 'core/assets/img/icon-settings.svg'
import { ReactComponent as ArrowDown } from 'core/assets/img/arrow-down-icon.svg'
import { ReactComponent as ArrowDownBold } from 'core/assets/img/arrow-down-bold-icon.svg'
import { ReactComponent as ArrowUpBold } from 'core/assets/img/arrow-up-bold-icon.svg'
import { ReactComponent as Inbound } from 'core/assets/img/icon-inbound.svg'
import { ReactComponent as TagInfo } from 'core/assets/img/icon-tag-info.svg'
import { ReactComponent as CheckGreen } from 'core/assets/img/check-green-icon.svg'
import { ReactComponent as LeftArrow } from 'core/assets/img/left-arrow.svg'
import { ReactComponent as Info } from 'core/assets/img/icon-info.svg'
import { ReactComponent as Close } from 'core/assets/img/close-icon.svg'
// import { ReactComponent as LeftArrow1 } from 'core/assets/img/icon-left-arrow.svg'
import { ReactComponent as Loader } from 'core/assets/img/loader-black.svg'
import { ReactComponent as Rfid } from 'core/assets/img/rfid-icon.svg'
// import { ReactComponent as Close1 } from 'core/assets/img/close.svg'
import { ReactComponent as Outbound } from 'core/assets/img/icon-outbound.svg'
import { ReactComponent as Confirmed } from 'core/assets/img/confirmed-icon.svg'
import { ReactComponent as Loader1 } from 'core/assets/img/loader.svg'
import { ReactComponent as Pause } from 'core/assets/img/icon-pause.svg'
import { ReactComponent as RfidStation } from 'core/assets/img/logo-stylewhere-rfid-station.svg'
import { ReactComponent as Serial } from 'core/assets/img/serial-icon.svg'
import { ReactComponent as TransferToZone } from 'core/assets/img/transfer-to-zone-icon.svg'
import { ReactComponent as Transfers } from 'core/assets/img/icon-transfers.svg'
import { ReactComponent as HelpOutline } from 'core/assets/img/help-outline.svg'
import { ReactComponent as CheckFilled } from 'core/assets/img/check-filled.svg'
import { ReactComponent as Delete } from 'core/assets/img/delete.svg'
import { ReactComponent as SimpleItemList } from 'core/assets/img/simple-item-list.svg'
import { ReactComponent as SimpleParcelList } from 'core/assets/img/simple-parcel-list.svg'
import { ReactComponent as Dots } from 'core/assets/img/icon-dots.svg'
import * as Onde from 'core/assets/animations/onde.json'

export const animations = {
  Onde: (Onde as any).default,
}

export const img = {
  checkGreenIcon: CheckGreen,
  animatedBar: AnimatedBar,
  antennaIcon: Antenna,
  antennaOnIcon: AntennaOn,
  arrowDownBoldIcon: ArrowDownBold,
  arrowDownIcon: ArrowDown,
  arrowUpBoldIcon: ArrowUpBold,
  checkFilled: CheckFilled,
  closeIcon: Close,
  close: Close,
  confirmedIcon: Confirmed,
  delete: Delete,
  helpOutline: HelpOutline,
  iconBarcode: Barcode,
  iconEncoding: Encoding,
  iconFullscreen: Fullscreen,
  iconInbound: Inbound,
  iconInfo: Info,
  iconLeftArrow: LeftArrow,
  iconOutbound: Outbound,
  iconPause: Pause,
  iconPlay: Play,
  iconRetry: Retry,
  iconSearch: Search,
  iconSettings: Settings,
  iconTagInfo: TagInfo,
  iconTransfers: Transfers,
  leftArrow: LeftArrow,
  loaderBlack: Loader,
  loader: Loader1,
  logoStylewhereRfidStation: RfidStation,
  nfcIcon: Nfc,
  rfidIcon: Rfid,
  serialIcon: Serial,
  transferToZoneIcon: TransferToZone,
  simpleItemList: SimpleItemList,
  simpleParcelList: SimpleParcelList,
  dots: Dots,
}

export const sounds = {
  confirmation: require('./sounds/confirmation.m4a'),
  error: require('./sounds/error.m4a'),
  fail: require('./sounds/fail.m4a'),
  next: require('./sounds/next.m4a'),
  scan: require('./sounds/scan.m4a'),
  success: require('./sounds/success.m4a'),
  tap: require('./sounds/tap.m4a'),
}
