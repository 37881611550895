/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { ProductInfoField, TmrItem, TmrTransfer } from 'stylewhere/api'
import { Box, Card, IdentifierRow, Modal, ProductInfoBox, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  onClose: () => void
  item: TmrItem
  visible: boolean
}

interface State {
  transfers?: TmrTransfer[]
}

export class ItemInfoModal extends Component<Props, State> {
  state: State = {}
  productFields: ProductInfoField[] = [
    { path: 'code', label: __(T.misc.upc) },
    { paths: ['color.value', 'color.description'], label: __(T.misc.color) },
    { paths: ['size.value', 'size.description'], label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
    { path: 'attributes.tipologiacapo.value', label: __(T.misc.typelogy) },
  ]

  render() {
    const { visible, onClose, item } = this.props
    // const { transfers } = this.state
    return (
      <Modal size="5xl" onClose={onClose} visible={visible} fullContent>
        <Box ph={35} pv={10}>
          <Modal.Title style={{ flex: 0 }}>Item Info</Modal.Title>
        </Box>
        <Spacer />
        <Box flex row>
          <Box flex={3} ph={35} pb={20}>
            <ProductInfoBox product={item.product} fields={this.productFields} />
            <Spacer />
          </Box>
          <Box flex={2} pr={18} pb={40} width={0}>
            <Card mb={10} flex title="Item">
              <ProductInfoBox.Field
                label={`${__(T.misc.place)} ${__(T.misc.zone)}`}
                value={`${item.zone?.place?.description ?? item.zone?.place?.code} - ${
                  item.zone?.description ?? item.zone?.code
                }`}
              />
              {Object.keys(item.attributes ?? {})?.map((key) => (
                <ProductInfoBox.Field label={key} value={item.attributes?.[key] as any} />
              ))}
            </Card>
            <Card title="Identifiers">
              {item.identifiers
                ?.sort((idf) => (idf.role === 'serial' ? -1 : 0))
                ?.sort((idf) => (idf.role === 'data_matrix' ? 1 : 0))
                .map((identifier, index) => (
                  <IdentifierRow identifier={identifier} last={index === item.identifiers.length - 1} />
                ))}
            </Card>
          </Box>
        </Box>
      </Modal>
    )
  }
}
