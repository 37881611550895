import React, { ImgHTMLAttributes, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'

interface Props extends ImgHTMLAttributes<any> {
  onEmptyImageComponent?: JSX.Element
  variant?: 'boxed'
  transparent?: boolean
}

export const Image = (props: Props) => {
  const [src] = useState(props.src?.includes('assets/') ? `${process.env.PUBLIC_URL}/${props.src}` : props.src)
  const [error, seterror] = useState(false)

  const onError = () => {
    if (!error) seterror(true)
  }

  const { onEmptyImageComponent, ...rest } = props
  const render = () => {
    if (error && onEmptyImageComponent) return onEmptyImageComponent
    if (error) {
      const height = rest.height ?? 100
      const width = rest.width ?? 100
      return (
        <NoImageText
          transparent={props.transparent}
          center
          style={{
            height,
            minHeight: height,
            maxHeight: height,
            maxWidth: width,
            width,
            minWidth: width,
            ...rest.style,
          }}
        >
          IMAGE
          <br />
          MISSING
        </NoImageText>
      )
    }
    return <ImageCore {...rest} src={src} onError={onError} />
  }

  return <Container variant={props.variant}>{render()}</Container>
}

const Container = styled(Box)<{ variant?: 'boxed' }>`
  ${({ variant }) =>
    variant === 'boxed' &&
    ` padding: 30px 20px;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;`}
`

const ImageCore = styled.img``

const NoImageText = styled(Box)<{ transparent?: boolean }>`
  font-weight: 800;
  font-size: 10pt;
  text-align: center;
  color: rgba(0, 0, 0, 0.1);
  background-color: ${({ transparent, theme }) => (transparent ? 'transparent' : theme.background1)};
  border-radius: 10px;
  margin: auto;
`
