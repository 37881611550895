import { AppStore } from 'stylewhere/shared'
import config from 'stylewhere/config'
import { api, Product, responseErrorCheck } from 'stylewhere/api'

export class Products {
  static endpoint = 'products'

  static get(code: string): any {
    return api
      .get<any[]>(`products?code=${code}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async getProductsByCodes(upcs: string[]): Promise<Product[]> {
    return api
      .post<Product | any>(`${this.endpoint}/advancedSearch`, {
        codes: upcs,
        limit: 50000,
      })
      .then(responseErrorCheck)
  }

  static getImageUrl(code?: string): string {
    if (!code || !AppStore.authToken) {
      return ''
    }
    const imageUrl = `${config.endpoint}${Products.endpoint}/image/${code}/full/full/0/default.jpg?x-tmr-token=${AppStore.authToken}`
    return imageUrl
  }
}
