import Keycloak from 'keycloak-js'
import config from 'stylewhere/config'
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: `${config.keycloakUrl}auth`,
  realm: config.keycloakRealm,
  clientId: config.keycloakClientId,
})

export default keycloak
