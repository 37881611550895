import React from 'react'
import styled from '@emotion/styled'
import { GroupedItemsByProduct, Products } from 'stylewhere/api'
import { Image, SmallTagCounter } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

function ShipmentProductStructure(totalProductsQuantity: number) {
  const ProductImage = styled(Image)`
    object-fit: contain;
    width: 125px;
    height: 100px;
    padding: 10px;
  `
  return [
    {
      label: '',
      width: 140,
      customRender: ({ product }: GroupedItemsByProduct) => (
        <ProductImage style={{ marginTop: 10, marginBottom: 10 }} src={Products.getImageUrl(product.code)} />
      ),
    },
    {
      width: 240,
      label: __(T.misc.upc),
      customValue: ({ product }: GroupedItemsByProduct) => `${product.code}`,
    },
    {
      flex: 2,
      label: __(T.misc.model_part_color_size),
      customValue: ({ product }: GroupedItemsByProduct) =>
        `${product.material?.value ?? '--'} / ${product.style?.value ?? '--'} / ${product.color?.value ?? '--'} / ${
          product.size?.value ?? '--'
        }`,
    },
    {
      label: __(T.misc.quantity, { quantity: totalProductsQuantity }),
      width: 180,
      customRender: (product: GroupedItemsByProduct) => (
        <SmallTagCounter detected={product.detected} unexpected={product.unexpected} expected={product.expected} />
      ),
    },
  ]
}

export default {
  ShipmentProductStructure,
}
