import React, { Component } from 'react'
import {
  AntennaButton,
  Page,
  Spacer,
  TagCounter,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
} from 'stylewhere/components'
import { DecodedItem, SimpleItemList } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  SimpleItemListOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  AppStore,
  RfidReader,
  getDataFromSchema,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { SimpleItemListExtensions } from 'stylewhere/extensions'
import { showToast, showToastError } from 'stylewhere/shared/utils'

export default class SimpleItemListReading extends Component<OperationReadingProps, OperationReadingState> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleItemListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleItemListExtensions.formSchema(this.operation)

  state: OperationReadingState = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
  }

  async componentDidMount() {
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
  }

  setRfidReaderDecode = () => {
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, {
      url: SimpleItemList.batchValidateEndpoint(),
      payload: decodePayload,
    })
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const items = await OperationReadingProvider.processDecodedItems(
      this.operation,
      itemMapFromReader,
      this.state.items,
      this.state.formData,
      SimpleItemListExtensions
    )

    this.setState({ items })
  }

  removeItem(decodedItem: DecodedItem) {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-item-list/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  onConfirm = async () => {
    const { items, formData } = this.state
    try {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')
      const confirmResult = await SimpleItemList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation.placeId,
        itemIds: items.flatMap(({ item }) => (item?.id ? item.id : [])),
      })
      await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.goBack()
    } catch (err) {
      showToastError(err)
    }
  }

  showConfirmButton() {
    const { items } = this.state
    if (items.filter((itm) => itm.item?.status !== 'ignored' && itm.item?.status !== 'error').length > 0)
      return <Button title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  render() {
    const { items, formData, loading } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            setFormData: async (fd) => {
              if (!(await SimpleItemListExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
              this.setState({ formData: fd })
              this.setRfidReaderDecode()
            },
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.length} />
            <AntennaButton onClear={this.onClear} hideClear={items.length === 0} />
            <Spacer />
            <OperationReadingCounters items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={SimpleItemListExtensions}
            items={items}
            operation={this.operation}
          />
        </Page.Content>
      </Page>
    )
  }
}
