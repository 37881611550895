import React from 'react'
import styled from '@emotion/styled'

const Tracker = styled.div`
  margin: 0;
  height: 5px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1);
`

const ProgressInTracker = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => percentage}%;
  height: 100%;
  background-color: green;
  border-radius: 2px;
`

export function ProgressBar({ percentage = 0, style }: { percentage: number; style?: React.CSSProperties }) {
  return (
    <Tracker style={style}>
      <ProgressInTracker percentage={percentage > 100 ? 100 : percentage} />
    </Tracker>
  )
}
