import React, { PureComponent } from 'react'
import { EmptyListMessage } from 'stylewhere/components'
import { List as VirtualizedList, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { __, T } from 'stylewhere/shared/i18n'
import ResizeObserver from 'rc-resize-observer'

interface Props {
  data: any[]
  emptyMessage?: string | JSX.Element
  rowRender: (item: any, index: number) => JSX.Element
  loading?: boolean
}

export class List extends PureComponent<Props> {
  _listRef: VirtualizedList
  cache = new CellMeasurerCache({
    fixedWidth: true,
  })

  static defaultProps: Partial<Props> = {
    emptyMessage: <span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.list_empty)}</span>,
  }

  _getRowHeight = ({ index }) => {
    return this.cache.rowHeight({ index })
  }

  rowRender = ({ index, parent, key, style }) => {
    return (
      <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
        {({ measure }) => (
          <div style={style}>
            <ResizeObserver onResize={measure}>{this.props.rowRender(this.props.data[index], index)}</ResizeObserver>
          </div>
        )}
      </CellMeasurer>
    )
  }

  render() {
    const { data, emptyMessage } = this.props
    if (data.length === 0) {
      return (
        <EmptyListMessage style={{ backgroundColor: 'white', borderRadius: 15 }} center>
          {emptyMessage}
        </EmptyListMessage>
      )
    }
    return (
      <div style={{ padding: 1, flex: '1 1 auto', minHeight: 40 }}>
        <AutoSizer>
          {({ height, width }) => (
            <VirtualizedList
              ref={(r) => (this._listRef = r!)}
              width={width}
              rowHeight={this._getRowHeight}
              height={height}
              rowCount={data.length}
              rowRenderer={this.rowRender}
              overscanIndicesGetter={({ cellCount, stopIndex }) => {
                const overScanBottom = stopIndex + 5
                return {
                  overscanStartIndex: 0,
                  overscanStopIndex: (overScanBottom > cellCount ? cellCount : overScanBottom) - 1,
                }
              }}
            />
          )}
        </AutoSizer>
      </div>
    )
  }
}
