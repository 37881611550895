import React, { useState } from 'react'
import styled from '@emotion/styled'
import { AppStore } from 'stylewhere/shared'

const EmulateButtonStyle = styled.button`
  width: fit-content;
  color: rgb(30, 205, 150);
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
  align-self: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  :focus {
    outline: black;
  }
  :hover {
    filter: brightness(95%);
  }
`

interface Props {
  title: string
  onPress?: () => void
  style?: React.CSSProperties
  type?: string
}

export function EmulateButton({ title, onPress, ...restProps }: React.PropsWithChildren<Props>) {
  const [internalLoading, setinternalLoading] = useState(false)
  const onPrePress = async () => {
    if (internalLoading) return
    if (!onPress) return

    setinternalLoading(true)
    await onPress()
    setinternalLoading(false)
  }

  if (!AppStore.emulation) return <></>

  return (
    <EmulateButtonStyle onClick={onPrePress} {...(restProps as any)}>
      {title}
    </EmulateButtonStyle>
  )
}
