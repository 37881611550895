import { api, TmrWorkstation, responseErrorCheck, TmrBaseResource } from 'stylewhere/api'

export class Workstations extends TmrBaseResource {
  static endpoint = 'edge/api/v1/workstations'

  static searchByPlaceId(placeId) {
    return api
      .get<TmrWorkstation[]>(`${this.endpoint}`, {
        'place.id': placeId,
      })
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
