import React, { Component } from 'react'
import Hotkeys from 'react-hot-keys'
import styled from '@emotion/styled'
import { MotionStyle } from 'framer-motion'

import { RfidReader, Router } from 'stylewhere/shared'
import { Box, EmulationModal, Header, HeaderAction, HeaderDetail, PageFormDetail } from 'stylewhere/components'

// eslint-disable-next-line
export type PageHeader = { details?: HeaderDetail; actions?: HeaderAction[] }

interface Props {
  title: string
  onBackPress?: (() => void) | false
  hideTopBar?: boolean
  topBarRight?: JSX.Element
  loading?: boolean
  header?: PageHeader | null
  headerRight?: JSX.Element
  enableEmulation?: boolean
}

interface State {
  showEmulationModal: boolean
}

export class Page extends Component<Props, State> {
  static Title = styled(Box)`
    font-weight: bold;
    font-size: 30px;
  `

  static TopBar = ({ children }: { children: React.ReactNode }) => {
    return (
      <Box mb={40} pv={10} row>
        {children}
      </Box>
    )
  }

  static Sidebar = ({
    style,
    width = 360,
    children,
  }: {
    style?: MotionStyle
    width?: number
    children: React.ReactNode
  }) => {
    return (
      <Box style={style} width={width} bgGrey>
        <Box flex pv={30} pl={30}>
          {children}
        </Box>
      </Box>
    )
  }

  static Content = ({
    children,
    style,
    notBoxed = false,
    overflowY,
  }: {
    style?: MotionStyle
    children: React.ReactNode
    notBoxed?: boolean
    overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
  }) => {
    return (
      <ContentInner overflowY={overflowY} flex style={style} notBoxed={notBoxed}>
        {children}
      </ContentInner>
    )
  }

  state: State = {
    showEmulationModal: false,
  }

  componentDidMount() {
    const title = `Stylewhere | RFID Station - ${this.props.title}`
    if (title !== document.title) document.title = title
  }

  onEmulationConfirm = (input?: string) => {
    this.setState({ showEmulationModal: false })
    if (!input) return
    const codes: string[] = String(input).replace(/ /g, '').split(',') ?? []
    RfidReader.emulateTagAuto(codes.map((code) => ({ epc: code })))
  }

  onKeyDown = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ showEmulationModal: !this.state.showEmulationModal })
  }

  render() {
    const { title, onBackPress, header, headerRight, children, enableEmulation } = this.props
    const { showEmulationModal } = this.state

    return (
      <>
        <Hotkeys
          keyName="command+e,ctrl+e,ctrl+m,command+m,alt+m"
          onKeyDown={this.onKeyDown}
          disabled={!enableEmulation}
        >
          <PageContainer>
            {header !== null && (
              <Header
                title={title}
                actions={header?.actions}
                details={header?.details}
                onBackPress={onBackPress ?? (() => Router.navigate('/'))}
              >
                {headerRight}
              </Header>
            )}
            <ContentWrapper flex row>
              {children}
              {header?.details && header.details.formSchema.length > 2 && (
                <LeftHeaderContainer>
                  <PageFormDetail
                    changeButtonStyle={{ margin: 10 }}
                    detailStyle={{ overflowWrap: 'break-word' }}
                    data={header.details.data}
                    formSchema={header.details.formSchema}
                    setFormData={header.details.setFormData}
                  />
                </LeftHeaderContainer>
              )}
            </ContentWrapper>
          </PageContainer>

          {enableEmulation && showEmulationModal && (
            <EmulationModal
              title="Emulation Modal"
              onClose={() => {
                this.setState({ showEmulationModal: false })
              }}
              onConfirm={this.onEmulationConfirm}
            />
          )}
        </Hotkeys>
      </>
    )
  }
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
`

const ContentWrapper = styled(Box)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.background1};
`

const ContentInner = styled(Box)<{
  notBoxed?: boolean
  overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
}>`
  overflow-y: ${({ overflowY = 'auto' }) => overflowY};
  scrollbar-width: none;
  padding: ${({ theme }) => theme.padding};
  box-shadow: ${({ notBoxed }) => (notBoxed ? 'none' : `0 2px 10px rgba(0, 0, 0, 0.05)`)};
  border-radius: ${({ notBoxed }) => (notBoxed ? 'none' : `15px`)};
  background-color: ${({ notBoxed, theme }) => (notBoxed ? 'inherit' : theme.background2)};
`

const LeftHeaderContainer = styled(Box)`
  background: #eaeaea;
  border-radius: 15px;
  margin: 30px;
  margin-left: 0;
  max-width: 20vw;
  height: fit-content;
  overflow-y: auto;
  max-height: calc(100vh - 160px);
`
