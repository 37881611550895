import {
  ShippingParcelUpdateRequest,
  ShippingParcelCreateRequest,
  ShippingParcel,
  responseErrorCheck,
  api,
  TmrBaseResource,
} from 'stylewhere/api'

import { ShippingOperationConfig } from 'stylewhere/shared'

export class Shippings extends TmrBaseResource {
  static endpoint = 'logistics/api/v1/operations'

  static getOperationEndpoint(operation: ShippingOperationConfig) {
    if (operation.type === 'INBOUND') return `${this.endpoint}/inbound`
    if (operation.type === 'OUTBOUND') return `${this.endpoint}/outbound`
    if (operation.type === 'PACK') return `${this.endpoint}/pack`
    return ''
  }

  // eslint-disable-next-line consistent-return
  static batchValidateEndpoint(operation: ShippingOperationConfig) {
    return `${this.getOperationEndpoint(operation)}/batchValidate`
  }

  static startParcel(operation: ShippingOperationConfig, data: ShippingParcelCreateRequest) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/startParcel`, data)
      .then(responseErrorCheck)
  }

  static updateParcel(operation: ShippingOperationConfig, data: ShippingParcelUpdateRequest) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/updateParcelContent`, data)
      .then(responseErrorCheck)
  }

  static confirmParcel(operation: ShippingOperationConfig, data: ShippingParcelCreateRequest, force = false) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmParcel${force ? '/force' : ''}`, data)
      .then(responseErrorCheck)
  }

  static parcelByIdentifier(operation: ShippingOperationConfig, identifier: string) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/parcelByIdentifier`, {
        operationId: operation.id,
        identifierCode: identifier,
      })
      .then(responseErrorCheck)
  }
}
