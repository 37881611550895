import _ from 'lodash'
import it from 'core/translations/it'

export default _.merge(
  {
    titles: {
      outbound_bthp: 'Outbound Certificato BTHP',
    },
    simple_parcel_list: {
      to_read: 'Non letto',
      ok: 'Ok',
      force: 'Forza',
      force_parcel: 'Forza collo {{code}}',
      force_parcel_desc: 'Inserisci il PIN utente per forzare il collo',
      forced: 'Forzato',
      unexpected: 'Inatteso',
      remove: 'Rimuovi',
      invalid_pin: 'PIN utente non valido',
    },
    create_parcel_gate: {
      width: 'Larghezza',
      height: 'Altezza',
      depth: 'Profondità',
      tare: 'Tara',
      description: 'Descrizione',
    },
    misc: {
      typelogy: 'Tipologia',
    },
  },
  it
)
