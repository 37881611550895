import React from 'react'
import { useParams } from 'react-router-dom'
import { AntennaButton, Box, EmptyListMessage, FormSchemaForm, Page } from 'stylewhere/components'
import { Router, RemoteOperation, FormSchemaData, FormSchema, OperationConfig } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'
import type { Routes } from 'stylewhere/pages'

import { Extensions } from 'core/extensions/Extensions'

type Props<OC extends OperationConfig> = {
  submitPath: Routes
  onSubmit?: (formData: FormSchemaData, operation: OC, schema: FormSchema) => void
  startWithRfidSearch?: boolean
  extensions: typeof Extensions
  formSchemaAdditionalData?: any
}

/**
 * *Ricorda*: Richiede un route param `opCode`
 */
export const OperationStart = <OC extends OperationConfig>({
  submitPath,
  onSubmit,
  extensions,
  startWithRfidSearch,
  formSchemaAdditionalData,
}: Props<OC>) => {
  const { opCode } = useParams<{ opCode: string }>()
  const operation = RemoteOperation.getOperationConfig<OC>(opCode)

  const schema = extensions.formSchema(operation, formSchemaAdditionalData)

  const submit = async (formData: FormSchemaData) => {
    if (!(await extensions.formDataIsValid(formData, operation, schema))) return
    if (onSubmit) {
      onSubmit(formData, operation, schema)
    } else {
      Router.navigate(submitPath, { opCode }, { state: { formData } })
    }
  }

  // Se non ci sono campi, fai un redirect immediato alla fase di lettura
  if (schema.length === 0) {
    submit({})
    return null
  }

  return (
    <Page
      title={operation.description}
      onBackPress={() => Router.navigate('/')}
      enableEmulation={!!startWithRfidSearch}
    >
      <Page.Content notBoxed>
        <Box flex>
          {!!startWithRfidSearch && (
            <Box flex center style={{ backgroundColor: 'white', borderRadius: 15 }}>
              <Box center>
                <EmptyListMessage center style={{ marginBottom: 20 }}>
                  Start antenna and read one tag
                </EmptyListMessage>
                <AntennaButton />
              </Box>
            </Box>
          )}
          {!startWithRfidSearch && (
            <Box flex center>
              <FormSchemaForm schema={schema} submitText={__(T.misc.next)} onSubmit={submit} formContext="start" />
            </Box>
          )}
        </Box>
      </Page.Content>
    </Page>
  )
}
