import { AppConfiguration } from 'stylewhere/types'

const config: AppConfiguration = {
  endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://stylewhere-dev.temera.it/',
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://sso-dev.temera.it/',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'temera',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'stw-core-station',
}

export default config
