import Base from 'core/pages/SimpleParcelList/SimpleParcelListStart'
import { FormSchema, SimpleParcelListOperationConfig } from 'stylewhere/shared'
import { ShippingParcel, Parcels } from 'stylewhere/api'

export default class SimpleParcelListStart extends Base {
  async getParcels(
    formData: { shipper?: { code: string } },
    operation: SimpleParcelListOperationConfig,
    schema: FormSchema
  ) {
    if (operation.code === 'certified-outbound') {
      if (!formData.shipper) throw new Error('Missing pallet')
      return (await Parcels.search<ShippingParcel>({ palletCode: formData.shipper.code })).content
    }
    return super.getParcels(formData, operation, schema)
  }
}
