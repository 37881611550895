import React from 'react'
import Base from 'core/pages/SimpleItemList/SimpleItemListReading'
import {
  AntennaButton,
  Box,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
} from 'stylewhere/components'
import { SimpleItemListExtensions } from 'stylewhere/extensions'
import { AppStore, getDataFromSchema, RfidReader } from 'stylewhere/shared'
import { SimpleItemList } from 'stylewhere/api'
import { showToast, showToastError } from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/shared/i18n'

export default class SimpleItemListReading extends Base {
  showConfirmButton = () => {
    const { formData } = this.state
    if (formData?.attributes?.enableAutoPrint) {
      return null
    }
    return super.showConfirmButton()
  }

  onConfirm = async () => {
    const { items, formData } = this.state
    try {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')

      if (this.operation.code === 'create-parcel-gate') {
        confirmData.attributes.userAction = 'CONFIRM'
      }

      const confirmResult = await SimpleItemList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation.placeId,
        itemIds: items.flatMap(({ item }) => (item?.id ? item.id : [])),
      })
      await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.goBack()
    } catch (err) {
      showToastError(err)
    }
  }

  onClear = async () => {
    if (this.operation.code === 'create-parcel-gate') {
      const { items, formData } = this.state
      try {
        const confirmData = getDataFromSchema(formData, this.formSchema)
        await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
        if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')
        confirmData.attributes.userAction = 'CLEAR'
        const confirmResult = await SimpleItemList.save({
          ...confirmData,
          operationId: this.operation.id,
          operationPlaceId: AppStore.defaultWorkstation.placeId,
          itemIds: items.flatMap(({ item }) => (item?.id ? item.id : [])),
        })
        await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.generic_success_canceled, { code: this.operation.description }),
          status: 'success',
        })
      } catch (err) {
        showToastError(err)
      }
    }

    RfidReader.clear()
    this.setState({ items: [] })
  }

  render() {
    if (this.operation.code !== 'location-assignment') return super.render()

    // Location assignment non ha la modale di modifica (prop header di Page)
    const { items, formData, loading } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            // setFormData: async (fd) => {
            //   if (!(await SimpleItemListExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
            //   this.setState({ formData: fd })
            //   this.setRfidReaderDecode()
            // },
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.length} />
            <AntennaButton onClear={this.onClear} hideClear={items.length === 0} />
            <Spacer />
            <OperationReadingCounters items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            actionItemCallback={() => alert('ciao')}
            extension={SimpleItemListExtensions}
            items={items}
            operation={this.operation}
          />
        </Page.Content>
      </Page>
    )
  }
}
