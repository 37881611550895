import { Component } from 'react'
import { img } from 'stylewhere/assets'

export class Icons extends Component {
  static Barcode = img.iconBarcode
  static Play = img.iconPlay
  static AnimatedBar = img.animatedBar
  static Encoding = img.iconEncoding
  static Retry = img.iconRetry
  static Nfc = img.nfcIcon
  static Antenna = img.antennaIcon
  static Fullscreen = img.iconFullscreen
  static Search = img.iconSearch
  static AntennaOn = img.antennaOnIcon
  static Settings = img.iconSettings
  static ArrowDown = img.arrowDownIcon
  static ArrowDownBold = img.arrowDownBoldIcon
  static ArrowUpBold = img.arrowUpBoldIcon
  static Inbound = img.iconInbound
  static TagInfo = img.iconTagInfo
  static CheckGreen = img.checkGreenIcon
  static LeftArrow = img.iconLeftArrow
  static Info = img.iconInfo
  static Close = img.closeIcon
  static LeftArrow1 = img.iconLeftArrow
  static Loader = img.loaderBlack
  static Rfid = img.rfidIcon
  static Close1 = img.close
  static Outbound = img.iconOutbound
  static Confirmed = img.confirmedIcon
  static Loader1 = img.loader
  static Pause = img.iconPause
  static RfidStation = img.logoStylewhereRfidStation
  static Serial = img.serialIcon
  static TransferToZone = img.transferToZoneIcon
  static Transfers = img.iconTransfers
  static Help = img.helpOutline
  static CheckFilled = img.checkFilled
  static Delete = img.delete
  static SimpleItemList = img.simpleItemList
  static SimpleParcelList = img.simpleParcelList
  static Dots = img.dots
}
