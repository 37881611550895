import {
  api,
  Auth,
  ConfirmModalParams,
  TmrPrinter,
  TmrUser,
  TmrWorkstation,
  TmrZone,
  Workstations,
  // Printers,
  // Zones,
} from 'stylewhere/api'
import { ModalType } from 'stylewhere/types'
import { changeLanguage as changeLanguage_i18n } from 'stylewhere/i18n'
import { __isDev } from 'stylewhere/utils'
import { Storage, Router, RemoteOperation, keycloak } from 'stylewhere/shared'
import { ConnectionError } from 'stylewhere/shared/errors'

export interface Emulation {
  name: string
  value: string
}

class AppStore {
  authToken?: string
  defaultWorkstation?: TmrWorkstation
  defaultPrinter?: TmrPrinter
  workstations?: TmrWorkstation[]
  printers: TmrPrinter[] = []
  loggedUser?: TmrUser
  zones?: TmrZone[]
  language = 'it'
  languages = ['it', 'en']
  emulation = __isDev
  dailyItems = 0
  emulationList: Emulation[] = []
  keycloakEvent?: {
    eventType:
      | 'onReady'
      | 'onInitError'
      | 'onAuthSuccess'
      | 'onAuthError'
      | 'onAuthRefreshSuccess'
      | 'onAuthRefreshError'
      | 'onAuthLogout'
      | 'onTokenExpired'
    error?: { error: string; error_description: string }
  }

  callbacks: { [name: string]: any } = {}
  confirmModalParams: ConfirmModalParams = { title: '', message: '', onConfirm: () => {}, onCancel: () => {} }
  toggleConfirmModalHandler?: (show: boolean) => void

  openModal?: (modal: ModalType) => void
  closeModal?: (id: string) => void

  reloadRouting!: () => void

  async loadInitialData() {
    // check language
    this.language = await Storage.load('language', 'it')
    changeLanguage_i18n(this.language)

    //check if is present a custom endpoint
    api.setBaseURL(await Storage.load('endpoint', api.getBaseURL()))

    await this.loadAuthToken()
    if (this.authToken) {
      try {
        // load users and remote operations configurations in parallel
        const results = await Promise.all([Auth.loggedUser(), RemoteOperation.load()])
        this.loggedUser = results[0]

        if (this.loggedUser) {
          const wsResponse = await Workstations.search<TmrWorkstation>()
          this.workstations = wsResponse.content
          // try {
          //   this.printers = await Printers.search()
          // } catch (e) {
          //   //ignore
          // }
          if (this.workstations && this.workstations.length === 1) {
            await this.saveDefaultWorkstation(this.workstations[0])
          } else if (this.workstations && this.workstations.length > 1) {
            const dWorkstation = await this.getDefaultWorkstation()
            this.defaultWorkstation = this.workstations.find((w) => w.id === dWorkstation?.id)
          }
        }

        this.defaultPrinter = await this.getDefaultPrinter()
        // this.zones = await Zones.search()
        this.dailyItems = await Storage.load(`dailyItems`, 0)
        this.emulation = await Storage.load(`emulation`, false)
        this.emulationList = await this.loadEmulationList()
      } catch (err) {
        if (err instanceof ConnectionError) {
          Router.navigate('/connection-error')
        } else {
          throw err
        }
      }
    }
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    await Storage.save('language', language)
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    Router.reloadApp()
  }

  getZoneByType(type: 'STOCK' | 'SOLD' | 'INBOUND' | 'LOST') {
    if (!this.zones) return undefined

    return this.zones.find((zone) => zone.type === type)
  }

  async enableEmulation(emulation = true) {
    this.emulation = emulation
    await Storage.save(`emulation`, emulation)
  }

  async increaseDailyItems() {
    this.dailyItems++
    await Storage.save(`dailyItems`, this.dailyItems)
  }

  async resetDailyItems() {
    this.dailyItems = 0
    await Storage.save(`dailyItems`, 0)
  }

  async saveDefaultWorkstation(workstation: TmrWorkstation) {
    this.defaultWorkstation = workstation
    await Storage.save(`defaultWorkstation`, workstation)
    return this.defaultWorkstation
  }

  async getDefaultWorkstation() {
    this.defaultWorkstation = await Storage.load(`defaultWorkstation`, this.defaultWorkstation)
    return this.defaultWorkstation
  }

  async saveDefaultPrinter(printer: TmrPrinter) {
    this.defaultPrinter = printer
    await Storage.save(`defaultPrinter`, printer)
    return this.defaultPrinter
  }

  async getDefaultPrinter() {
    this.defaultPrinter = await Storage.load(`defaultPrinter`, this.defaultPrinter)
    return this.defaultPrinter
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    // await Auth.logout()
    Storage.remove(`authToken`)
    this.authToken = undefined
    Storage.remove(`defaultWorkstation`)
    this.defaultWorkstation = undefined
    Storage.remove(`defaultPrinter`)
    this.defaultPrinter = undefined
    Storage.remove(`dailyItems`)
    Storage.remove(`emulation`)
    this.loggedUser = undefined
    await keycloak.logout()
    if (this.reloadRouting) this.reloadRouting()
    else Router.reloadApp()
  }

  async loadAuthToken() {
    // const authenticated = await Auth.login()
    if (keycloak.token) {
      Auth.setHeaderAccessToken(keycloak.token)
      this.authToken = keycloak.token
    }

    return this.authToken
  }

  async saveEmulationList(emulationList: Emulation[]) {
    this.emulationList = emulationList
    await Storage.save(`emulationList`, emulationList)
    return this.emulationList
  }

  async loadEmulationList() {
    this.emulationList = await Storage.load(`emulationList`, this.emulationList)
    return this.emulationList
  }

  setConfirmModalHandler(handler) {
    this.toggleConfirmModalHandler = handler
  }

  showConfirmModal(title: string, message: string, onConfirm: () => void, onCancel?: () => void) {
    this.confirmModalParams = { title, message, onConfirm, onCancel: onCancel ?? undefined }
    this.toggleConfirmModalHandler?.(true)
  }

  hideConfirmModal() {
    this.toggleConfirmModalHandler?.(false)
  }
}

export default new AppStore()
