/* eslint-disable @typescript-eslint/no-unused-vars */
import { DecodedItem } from 'stylewhere/api'
import { OperationConfig } from 'core/shared/RemoteOperation'
import { FormSchema, FormSchemaData, jsonToFormSchema } from 'stylewhere/shared'

export abstract class Extensions {
  static formSchema({ formSchema }: OperationConfig, additionalData?: any): FormSchema {
    if (formSchema && Object.keys(formSchema).length > 0) return jsonToFormSchema(formSchema)
    return []
  }

  static async formDataIsValid(
    formData: FormSchemaData,
    operation: OperationConfig,
    schema: FormSchema
  ): Promise<boolean> {
    return true
  }

  static async processItem(
    operation: OperationConfig,
    formData: FormSchemaData,
    item: DecodedItem
  ): Promise<DecodedItem | null> {
    return item
  }

  static processRow<RowType extends { [key: string]: any }>(operation: OperationConfig, row: RowType): RowType | null {
    return row
  }

  static checkItemStatus(operation: OperationConfig, item: DecodedItem): DecodedItem | undefined {
    if (item.statuses?.some((status) => operation.itemStates.ignore.includes(status)) || !item.item) return undefined
    if (item.statuses?.some((status) => operation.itemStates.warning.includes(status))) item.item.status = 'warning'
    if (item.statuses?.some((status) => operation.itemStates.error.includes(status))) item.item.status = 'error'
    return item
  }

  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    // Possono essere una lista di items, di parcels o altro a seconda del tipo di operazione
    objects: O
  ): Promise<boolean> {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static async afterConfirm(operation: OperationConfig, data: FormSchemaData, confirmResult: any): Promise<void> {}
}
