import React, { useState } from 'react'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import FullScreen from 'screenfull'
import { Box, Icons, KeyValueRow, SettingsModal, LeftContainer, Spacer } from 'stylewhere/components'
import { api } from 'stylewhere/api'
import config from 'stylewhere/config'
import { AppStore } from 'core/shared'
import { askUserConfirmation, openModal } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/i18n'

export function LeftHeader({
  header = true,
  style,
  children,
}: React.PropsWithChildren<{ header?: boolean; style?: any }>) {
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)

  const removeCustomEndpoint = async () => {
    const ok = await askUserConfirmation(
      'Rimuovi Endpoint Custom',
      `Vuoi tornare all'endpoint utilizzato di default nella station?`
    )

    if (ok) AppStore.setEndpoint(undefined)
  }

  const showSupportInfoModal = () => {
    openModal({
      title: __(T.titles.support),
      size: 'lg',
      body: (
        <Box>
          <KeyValueRow label="Mail" variant="grey" value="support@temera.it" />
          <Spacer />
          <KeyValueRow label={__(T.misc.phone)} variant="grey" value="+39 0550981917" />
          <Spacer />
          <KeyValueRow
            label={__(T.misc.version)}
            variant="grey"
            value={`${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_COMMIT}${
              process.env.REACT_APP_BUILD_DATE &&
              format(new Date(process.env.REACT_APP_BUILD_DATE), ' (MM/dd/yyyy HH:mm)')
            }`}
          />
        </Box>
      ),
    })
  }

  return (
    <Container style={style}>
      {header && (
        <Box center mv={50}>
          <Icons.RfidStation />
        </Box>
      )}
      <LeftContainer style={{ flex: 5 }}>
        {children}
        <Spacer />
        {api.getBaseURL() !== config.endpoint && (
          <KeyValueRow
            variant="inset"
            label="Custom backend"
            valueStyle={{ fontSize: 18 }}
            value={api.getBaseURL()}
            onClick={removeCustomEndpoint}
          />
        )}
      </LeftContainer>
      <BottomButtons row>
        <BottomButton onClick={() => setsettingsModalVisible(true)}>
          <Icons.Settings style={{ width: 36, margin: 17, opacity: 0.8 }} />
        </BottomButton>
        <Spacer />
        <BottomButton
          onClick={() => {
            // @ts-ignore
            FullScreen.toggle()
          }}
        >
          <Icons.Fullscreen style={{ width: 36, margin: 17, opacity: 0.8 }} />
        </BottomButton>
        <Spacer />
        <BottomButton onClick={showSupportInfoModal}>
          <Icons.Help style={{ width: 36, margin: 17, opacity: 0.8 }} />
        </BottomButton>
      </BottomButtons>
      <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.background1};
  overflow-y: hidden;
  width: 550px;
  max-width: 550px;
  min-width: 550px;
`

const BottomButtons = styled(Box)`
  position: absolute;
  bottom: 50px;
  left: 50px;
`

const BottomButton = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`
