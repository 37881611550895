import React, { useEffect, useState } from 'react'
import { AppStore, formatFieldValue, FormSchema, FormSchemaData } from 'stylewhere/shared'
import { Box, KeyValueRow, Button, FormSchemaForm } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { closeModal, openModal } from 'stylewhere/shared/utils'

interface Props {
  data: FormSchemaData
  setFormData?: (newFormData: FormSchemaData) => void
  formSchema: FormSchema
  detailStyle?: React.CSSProperties
  changeButtonStyle?: React.CSSProperties
}

export const PageFormDetail: React.FC<Props> = ({ formSchema, detailStyle, changeButtonStyle, data, setFormData }) => {
  // Dobbiamo fare il preprocess dei della form perché non è detto che sia già stato fatto nel passaggio
  // start (per esempio, Inbound Warehouse non mostra la form nella Start)
  const [loading, setLoading] = useState(true)
  const [processedFormSchema, setProcessedFormschema] = useState<FormSchema>([])
  const [processedData, setProcessedData] = useState<FormSchemaData>([])
  useEffect(() => {
    const fetch = async () => {
      const processed = await FormSchemaForm.preprocessFormData({ schema: formSchema, initialValues: data })
      setProcessedFormschema(processed.schema)
      setProcessedData(processed.initialValues)
      setLoading(false)
      AppStore.callbacks.openModalForm = openModalForm
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchema, data])

  const openModalForm = (resolve?: () => void, reject?: () => void) => {
    openModal({
      id: 'editForm',
      size: '3xl',
      body: (
        <Box center>
          <FormSchemaForm
            style={{ minHeight: '50vh', justifyContent: 'space-evenly' }}
            schema={formSchema}
            initialValues={data}
            submitText={__(T.misc.save)}
            onSubmit={(newData) => {
              closeModal('editForm')
              setFormData?.(newData)
              resolve?.()
            }}
            onCancel={() => {
              closeModal('editForm')
              reject?.()
            }}
            formContext="read"
          />
        </Box>
      ),
      closeOnOverlayClick: false,
    })
  }

  return (
    <>
      {(processedFormSchema ?? formSchema).map((fs) => (
        <KeyValueRow
          key={fs.name}
          variant="raw"
          label={fs.label}
          valueStyle={detailStyle}
          value={formatFieldValue(processedData ?? data, fs)}
          loading={loading}
        />
      ))}
      {setFormData && (
        <Button style={changeButtonStyle} key="change" onClick={openModalForm} variant="secondary" size="default">
          {__(T.misc.change)}
        </Button>
      )}
    </>
  )
}
