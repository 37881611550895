import React from 'react'
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import { it } from 'date-fns/locale'
import 'core/theme/datepicker.scss'
import i18n from 'stylewhere/i18n'

registerLocale('it', it)

export const DatePicker: React.FC<ReactDatePickerProps> = (props) => (
  <ReactDatePicker
    locale={i18n.language}
    dateFormat={i18n.language === 'it' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
    {...props}
  />
)
