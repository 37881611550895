import React from 'react'
import { Box, BoxProps, Spacer } from 'stylewhere/components'
import { FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react'

interface Props {
  name: string
  id?: string
  label: string
  labelPosition?: 'left' | 'right'
  check?: boolean
  onChange: (checked: boolean) => void
  wrapperProps?: BoxProps
  disabled?: boolean
}

export const Switch: React.FC<Props> = ({
  name,
  id,
  label,
  labelPosition = 'left',
  check,
  onChange,
  wrapperProps,
  disabled = false,
}) => {
  const Label = (
    <FormLabel htmlFor={name} mb="0" cursor="pointer" flex="1" color={disabled ? '#eeeeee' : '#666666'}>
      {label}
    </FormLabel>
  )
  const CSwitch = (
    <ChakraSwitch
      onChange={(e) => onChange(e.target.checked)}
      isChecked={check}
      id={id ?? name}
      name={name}
      size="lg"
      disabled={disabled}
    />
  )

  return (
    <Box row vcenter {...wrapperProps}>
      {labelPosition === 'left' && (
        <>
          {CSwitch}
          <Spacer />
          {Label}
        </>
      )}
      {labelPosition === 'right' && (
        <>
          {Label}
          {CSwitch}
        </>
      )}
    </Box>
  )
}
