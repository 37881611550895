import _ from 'lodash'
import en from 'core/translations/en'

export default _.merge(
  {
    titles: {
      outbound_bthp: 'Outbound BTHP Certificate',
    },
    simple_parcel_list: {
      to_read: 'To Read',
      ok: 'Ok',
      force: 'Force',
      force_parcel: 'Force parcel {{code}}',
      force_parcel_desc: 'Insert user PIN to force parcel',
      forced: 'Forced',
      unexpected: 'Unexpected',
      remove: 'Remove',
      invalid_pin: 'User PIN invalid',
    },
    create_parcel_gate: {
      width: 'Width',
      height: 'Height',
      depth: 'Depth',
      tare: 'Tare',
      description: 'Description',
    },
    misc: {
      typelogy: 'Typelogy',
    },
  },
  en
)
