import { Shippings as Base } from 'core/api/Shippings'
import { responseErrorCheck, api, ShippingParcel, ShippingParcelCreateRequest } from 'stylewhere/api'
import { AppStore, ShippingOperationConfig } from 'stylewhere/shared'

export class Shippings extends Base {
  static confirmParcel(operation: ShippingOperationConfig, data: ShippingParcelCreateRequest, force = false) {
    if (data.attributes) {
      data.attributes.workstationCode = AppStore.defaultWorkstation?.code
    } else {
      data.attributes = { workstationCode: AppStore.defaultWorkstation?.code }
    }
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmParcel${force ? '/force' : ''}`, data)
      .then(responseErrorCheck)
  }
}
