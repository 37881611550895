import React, { Component } from 'react'
import _ from 'lodash'
import { Box, Form, FormSchemaFormField } from 'stylewhere/components'
import {
  fetchAutocompleteDefaultValues,
  FormSchema,
  FormSchemaData,
  FormSchemaField,
  getFieldValue,
  setFieldValue,
} from 'stylewhere/shared'
import { showToast } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
  initialValues: FormSchemaData
  schema: FormSchema
  onSubmit: (data: FormSchemaData) => void
  onCancel?: () => void
  submitText: string
  style?: React.CSSProperties
  disabled?: boolean
  formContext?: string
}

interface State {
  data: FormSchemaData
  loading: boolean
  schema: FormSchema
}

/**
 * Example
 * schema={[
        { placeholder: __(T.misc.username), name: 'username', required: true },
        { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
    ]}
 */
export class FormSchemaForm extends Component<Props, State> {
  unmounted?: boolean

  state: State = {
    loading: true,
    data: {},
    schema: [],
  }

  static defaultProps: Partial<Props> = {
    initialValues: {},
  }

  static async preprocessFormData({ schema, initialValues }: { schema: FormSchema; initialValues: FormSchemaData }) {
    // Converti i defaultValues dei campi autocomplete
    schema = await fetchAutocompleteDefaultValues(schema)

    // Imposta i valori iniziali dei campi
    schema.forEach((field) => {
      const initialValue = getFieldValue(initialValues, field)
      if (field.defaultValue !== undefined && initialValue === undefined) {
        setFieldValue(field.defaultValue, initialValues, field)
      }
    })

    return { schema, initialValues }
  }

  async componentDidMount() {
    const { schema, initialValues } = await FormSchemaForm.preprocessFormData(this.props)
    this.setState({ data: _.cloneDeep(initialValues), schema, loading: false })
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  handleSubmit = async (event: React.FormEvent) => {
    const { data } = this.state
    const { onSubmit } = this.props
    event.preventDefault()
    if (!this.state.loading) {
      this.setState({ loading: true })
      const errors: string[] = []
      try {
        this.props.schema.forEach((field) => {
          const value = getFieldValue(data, field)
          if (field.required && (value === undefined || value === null || value === '')) {
            errors.push(__(T.error.field_required, { label: field.label }))
            throw new Error('')
          }
        })
      } catch (error) {
        //
      }
      if (errors.length) {
        errors.forEach((error) => {
          showToast({
            title: __(T.error.error),
            description: error,
            status: 'error',
          })
        })
      } else {
        await onSubmit(JSON.parse(JSON.stringify(data)))
      }

      if (!this.unmounted) this.setState({ loading: false })
    }
  }

  handleChange = (value: any, field: FormSchemaField) => {
    const { data } = this.state
    setFieldValue(value, data, field)
    this.setState(data)
    field.onChange && field.onChange(value, data, (newData: any) => this.setState(newData))
  }

  render() {
    let hide = false
    const { disabled } = this.props
    const { submitText, style, initialValues, onCancel, formContext } = this.props
    const { data, loading, schema } = this.state

    // @todo Focus sul primo campo se nessun campo ha il focus
    const fields = schema.filter((field) => !field.hide)
    const columns = fields.length > 3

    const negateIs = (is: string) => {
      if (is === 'disabled') return 'enabled'
      if (is === 'enabled') return 'disabled'
      if (is === 'hidden') return 'visible'
      if (is === 'visible') return 'hidden'
      if (is === 'optional') return 'required'
      if (is === 'required') return 'optional'
      return false
    }

    const triggerDependency = (is: string, field: FormSchemaField, additionalValues: any) => {
      if (is === 'disabled') {
        field.disabled = true
        // Svuota il campo quando lo disabiliti
        setFieldValue(null, data, field)
      } else if (is === 'enabled') {
        field.disabled = false
      } else if (is === 'emptied') {
        setFieldValue(null, data, field)
      } else if (is === 'filledWithValue') {
        setFieldValue(additionalValues, data, field)
      } else if (is === 'hidden') {
        hide = true
      } else if (is === 'visible') {
        hide = false
      } else if (is === 'optional') {
        field.required = false
      } else if (is === 'required') {
        field.required = true
      } else if (is === 'endpointReplaced' && field.type === 'autocomplete') {
        /**
         * Sostituisce parti dinamiche dell'endpoint con i valori del campo whenField corrispondenti
         * Quindi per esempio:
         * {
         *   name: 'foo',
         *   type: 'text',
         *  },
         * {
         *   name: 'bar',
         *   type: 'autocomplete',
         *   endpoint: 'some/url/?code=:fooCode',
         *   dependencies: [
         *     {
         *       is: 'endpointReplaced',
         *       endpointReplaced: { fooCode: '' },
         *       whenField: 'foo',
         *       condition: 'isFilled',
         *     },
         *   ],
         * }
         * Se il valore del campo foo è "ciao", l'endpoint del campo bar diventa "some/url/?code=ciao"
         * (Se fooCode è una stringa vuota, prende l'intero valore del campo. Se invece è una stringa,
         * tratta il campo come un oggetto e prende il valore nidificato con la funzione lodash _.get)
         */
        Object.keys(additionalValues.replacements).forEach((token) => {
          const replacement = additionalValues.replacements[token]
          if (!field.rawEndpoint) field.rawEndpoint = field.endpoint
          field.endpoint = field.rawEndpoint.replaceAll(
            `:${token}`,
            replacement ? _.get(additionalValues.whenFieldValue, replacement) : additionalValues.whenFieldValue
          )
        })
      }
    }

    if (!data) return null

    return (
      <Form
        columns={columns}
        submitText={submitText}
        style={style}
        onCancel={onCancel}
        onSubmit={this.handleSubmit}
        loading={loading}
      >
        {fields.map((field, index) => {
          // Dipendenze tra campi (per ora ne gestiamo solo una)
          if (field.dependencies && field.dependencies.length) {
            field.dependencies.forEach((dependency) => {
              const whenField = fields.find(({ name }) => name === dependency.whenField)
              if (whenField) {
                const whenFieldValue = getFieldValue(data, whenField)
                const isEmpty = (v) => v === '' || v === null || v === undefined

                // Parametri addizionali che cambiano a seconda del tipo di effetto
                let additionalOptions
                if (dependency.is === 'filledWithValue') additionalOptions = dependency.filledWithValue
                else if (dependency.is === 'endpointReplaced')
                  additionalOptions = {
                    whenFieldValue,
                    replacements: dependency.endpointReplaced,
                  }

                if (
                  (dependency.condition === 'hasValue' && whenFieldValue === dependency.conditionValue) ||
                  (dependency.condition === 'isEmpty' && isEmpty(whenFieldValue)) ||
                  (dependency.condition === 'isFilled' && !isEmpty(whenFieldValue))
                ) {
                  // Dipendenza triggerata
                  triggerDependency(dependency.is, field, additionalOptions)
                } else {
                  // Dipendenza non triggerata (triggera l'effetto opposto)
                  const negated = negateIs(dependency.is)
                  if (negated) {
                    triggerDependency(negated, field, additionalOptions)
                  }
                }
              }
            })
          }

          return (
            <Box key={field.name}>
              {!hide && (
                <FormSchemaFormField
                  field={field}
                  index={index}
                  value={getFieldValue(data, field)}
                  defaultValue={getFieldValue(initialValues, field)}
                  disabled={disabled}
                  onChange={(v) => this.handleChange(v, field)}
                  formContext={formContext}
                />
              )}
            </Box>
          )
        })}
      </Form>
    )
  }
}
