import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Icons, Box, Button } from 'stylewhere/components'
import { DecodedItem } from 'stylewhere/api'
import { OperationConfig } from 'stylewhere/shared'
import { MotionStyle } from 'framer-motion'

interface Props<OC extends OperationConfig> {
  operation?: OC
  decodedItem: DecodedItem
  unexpected?: number
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem<string>) => void
}

interface State {
  modalVisible: boolean
}

export class GroupedItemRow<OC extends OperationConfig> extends Component<Props<OC>, State> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string, style?: MotionStyle) => (
    <Box
      row
      mr={15}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  renderUnknownItemRow = () => {
    const { decodedItem, onRemoveItem } = this.props
    return (
      <>
        <Row row height={120} center>
          <Box flex ph={16}>
            <ReadIdentifierCode>{decodedItem.identifierCode ?? 'Identifier N/A'}</ReadIdentifierCode>
          </Box>
          {!!onRemoveItem && (
            <Box m={8}>
              <Button onClick={() => onRemoveItem(decodedItem)} variant="secondary" size="small" circle>
                <Icons.Delete width={24} height={24} />
              </Button>
            </Box>
          )}
        </Row>
      </>
    )
  }

  renderItemRow = () => {
    const { decodedItem, onActionItem } = this.props
    const { product } = decodedItem.item!
    return (
      <>
        <Row row>
          {product.code && (
            <Box center mh={30}>
              <Icons.Rfid style={{ width: 50 }} />
            </Box>
          )}
          <Box flex>
            <Box mv={25}>
              <Box>
                <ReadIdentifierCode>{decodedItem.identifierCode}</ReadIdentifierCode>
                {decodedItem.item?.identifiers
                  .filter((idf) => idf.code !== decodedItem.identifierCode)
                  .map((identifier) => (
                    <IdentifierCode>
                      {identifier.role?.toUpperCase()}: {identifier.code}
                    </IdentifierCode>
                  ))}
              </Box>

              <Box bgGrey pv={8} ph={16} mt={12} mb={16} style={{ borderRadius: 5 }}>
                <b style={{ fontSize: 23 }}>{product.code}</b>
                <span style={{ fontSize: 16 }}>{product.description}</span>
              </Box>
              {this.renderStatusChip(
                `${decodedItem.item?.zone?.place.description ?? decodedItem.item?.zone?.place.code} - ${
                  decodedItem.item?.zone?.description ?? decodedItem.item?.zone?.code
                }`
              )}
              <Box row>{decodedItem.statuses?.map((status, index) => this.renderStatusChip(status, index))}</Box>
            </Box>
          </Box>
          {!!onActionItem && (
            <Box center mr={10} onClick={() => onActionItem(this.props.decodedItem)}>
              <Icons.Confirmed style={{ width: 50 }} />
            </Box>
          )}
        </Row>
      </>
    )
  }

  renderStatusChip = (status: string, index?: number) => {
    const { operation } = this.props
    const chipStyle = {
      warning: { backgroundColor: '#FFEFC6', color: '#D6A52A' },
      error: { backgroundColor: '#FF8474', color: '#e0402b' },
      default: { backgroundColor: '#DAE6ED', color: '#5E5E5E' },
    }
    let statusType = 'default'
    if (operation?.itemStates.warning.includes(status)) statusType = 'warning'
    if (operation?.itemStates.error.includes(status)) statusType = 'error'

    return (
      <Box
        key={index}
        mr={4}
        mb={4}
        pv={5}
        ph={8}
        style={{ ...chipStyle[statusType], width: 'fit-content', borderRadius: 5 }}
      >
        <b>{status.replaceAll('_', ' ')}</b>
      </Box>
    )
  }

  render() {
    const { decodedItem } = this.props
    if (!decodedItem.item?.id) return this.renderUnknownItemRow()

    return this.renderItemRow()
  }
}

const Row = styled(Box)`
  margin-bottom: 20px;
  border: 2px solid #edecec;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background2};
`

const ReadIdentifierCode = styled.label`
  font-weight: bold;
  font-size: 20pt;
`
const IdentifierCode = styled.label`
  font-size: 16pt;
`
const SmallValue = styled.label`
  font-weight: bold;
  font-size: 12pt;
  white-space: nowrap;
`
const SmallLabel = styled.label`
  font-size: 12pt;
  white-space: nowrap;
  margin-right: 5px;
`
