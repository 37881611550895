import { Theme } from '@emotion/react'
import '@emotion/styled'
import { extendTheme } from '@chakra-ui/react'

// Typings del tema predefinito
declare module '@emotion/react' {
  // eslint-disable-next-line no-shadow
  export interface Theme {
    foreground: string
    background1: string
    background2: string
    bgGrey: string
    primary: string
    secondary: string
    accent: string
    padding: string
    primaryBg: string
    secondaryBg: string
    headerHeight: string
  }
}

export const theme: Theme = {
  foreground: '#454545',
  background1: '#F2F2F2',
  background2: '#FFFFFF',
  bgGrey: '#D7D7D7',
  primary: '#CEFF00',
  secondary: '#222222',
  accent: '#2F80ED',
  primaryBg: 'linear-gradient(180deg, #CEFF00 60.77%, #CEFF00 60.79%, #B3DD00 229.23%)',
  secondaryBg: 'linear-gradient(180deg, #666666 15%, #525252 120%)',
  padding: '30px',
  headerHeight: '100px',
}

export const chakraTheme = extendTheme({
  colors: {
    // Gradiente generato da https://themera.vercel.app/ a partire dal colore accent #2F80ED
    blue: {
      '50': '#E8F1FD',
      '100': '#BDD7F9',
      '200': '#93BDF6',
      '300': '#69A3F2',
      '400': '#3F89EE',
      '500': '#1470EB',
      '600': '#1059BC',
      '700': '#0C438D',
      '800': '#082D5E',
      '900': '#04162F',
    },
  },
})
