import React from 'react'
import { Icons } from 'stylewhere/components'
import { Routes } from 'stylewhere/pages'
import { RemoteOperation } from 'stylewhere/shared'

export type DashboardButtonProps = {
  code: string
  operation: Routes
  routeParams?: any
  icon?: React.ReactNode
  text?: string
  displayPriority?: number
}

export const getDashboardMenuButtons = () => {
  const enabled = RemoteOperation.operations.filter((op) => op.enabled)

  const staticOperations: DashboardButtonProps[] = [
    {
      code: 'tag-info',
      operation: '/tag-info',
      icon: <Icons.TagInfo />,
      text: 'Tag Info',
      displayPriority: 1,
    },
  ]

  const transferToZone: DashboardButtonProps[] = enabled
    .filter((op) => op.type === 'TRANSFER_TO_ZONE')
    .map((op) => ({
      code: op.code,
      operation: '/transfer-to-zone/:opCode',
      icon: <Icons.TransferToZone />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const simpleItemList: DashboardButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_ITEM_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-item-list/:opCode',
      icon: <Icons.SimpleItemList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const simpleParcelList: DashboardButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_PARCEL_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-parcel-list/:opCode',
      icon: <Icons.SimpleParcelList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const shippingItemList: DashboardButtonProps[] = enabled
    .filter((op) => op.type === 'INBOUND' || op.type === 'OUTBOUND' || op.type === 'PACK')
    .map((op) => {
      let icon
      if (op.type === 'INBOUND') icon = <Icons.Inbound />
      if (op.type === 'OUTBOUND') icon = <Icons.Outbound />
      return {
        code: op.code,
        operation: '/shipping/:opCode',
        icon: icon,
        routeParams: { opCode: op.code },
        text: `${op.description}`,
        displayPriority: op.displayPriority ?? 0,
      }
    })

  const operations: DashboardButtonProps[] = [
    ...staticOperations,
    ...transferToZone,
    ...simpleItemList,
    ...simpleParcelList,
    ...shippingItemList,
  ].sort((a, b) => {
    if ((a.displayPriority ?? 0) > (b.displayPriority ?? 0)) {
      return -1
    }
    if ((a.displayPriority ?? 0) > (b.displayPriority ?? 0)) {
      return 1
    }
    return 0
  })

  return operations
}
