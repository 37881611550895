import React, { Component } from 'react'
import { DecodedItem, DecodedParcel, Parcels, ShippingParcel, SimpleParcelList } from 'stylewhere/api'
import {
  AntennaButton,
  Box,
  Button,
  DecodedItemsModal,
  GroupedParcelList,
  Modal,
  Page,
  SmallTextCounter,
  Spacer,
  TagCounter,
} from 'stylewhere/components'
import { SimpleParcelListExtensions } from 'stylewhere/extensions'
import { T, __ } from 'stylewhere/i18n'
import {
  AppStore,
  getDataFromSchema,
  OperationReadingProps,
  OperationReadingProvider,
  OperationReadingState,
  RemoteOperation,
  RfidReader,
  Router,
  SimpleParcelListOperationConfig,
} from 'stylewhere/shared'
import { closeModal, openModal, showToast, showToastError } from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  parcels: ShippingParcel[]
  identifierCodesByParcel: { [parcelId: string]: string[] }
  unexpectedDecodedParcels: { [epc: string]: DecodedParcel }
  ignoredEpcs: any[]
}

export default class SimpleParcelListReading extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleParcelListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleParcelListExtensions.formSchema(this.operation)

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    parcels: [],
    identifierCodesByParcel: {},
    unexpectedDecodedParcels: {},
    ignoredEpcs: [],
  }

  async componentDidMount() {
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.setState({ parcels: this.locationState.parcels ?? [] })
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(this.decodeFunction)
  }

  decodeFunction = async (identifierCodes: string[]) => {
    const { ignoredEpcs } = this.state
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    const result = await Parcels.decode({
      /** @todo Ripristinare operationId quando il BE supporterà le extensions per la SPL */
      // operationId: this.operation.id,
      identifierCodes,
      ...decodePayload,
    })

    const { parcels, identifierCodesByParcel, unexpectedDecodedParcels } = this.state

    await Promise.all(
      Object.keys(result).map(async (tagCode) => {
        const decodedParcel = result[tagCode]

        if (!decodedParcel.item) {
          ignoredEpcs.push(tagCode)
        } else {
          const parcel = parcels.find((p) => p.code === decodedParcel.parcelCode)
          if (!parcel) {
            if (!(tagCode in unexpectedDecodedParcels)) {
              unexpectedDecodedParcels[tagCode] = decodedParcel
            }
            return undefined
          }
          if (parcel.__detected) return undefined
          const processedParcel = await SimpleParcelListExtensions.processParcel(this.operation, decodedParcel, parcel)
          if (processedParcel && decodedParcel.item !== null) {
            processedParcel.__detected = true
            identifierCodesByParcel[processedParcel.id] = identifierCodes
          }
        }
        return undefined
      })
    )

    this.setState({ parcels, identifierCodesByParcel, ignoredEpcs })
  }

  removeItem = (decodedItem: DecodedItem) => {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-parcel-list/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [], parcels: this.locationState.parcels ?? [], unexpectedDecodedParcels: {} })
  }

  getDetected() {
    return this.state.parcels.filter(({ __detected }) => __detected)
  }

  getUnexpected() {
    return this.state.parcels.filter(({ __unexpected }) => __unexpected)
  }

  onConfirm = async () => {
    const { parcels, formData } = this.state
    try {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SimpleParcelListExtensions.beforeConfirm(this.operation, confirmData, parcels)
      const confirmResult = await SimpleParcelList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation!.placeId,
        parcelCodes: this.getDetected().map(({ code }) => code),
      })
      await SimpleParcelListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.goBack()
    } catch (err) {
      showToastError(err)
    }
  }

  /** La conferma è abilitata se c'è almeno un detected e nessun unexpected */
  showConfirmButton() {
    if (this.getDetected().length > 0 && this.getUnexpected().length === 0)
      return <Button title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  removeParcel(parcel: ShippingParcel) {
    try {
      const { parcels, identifierCodesByParcel } = this.state
      const idx = parcels.findIndex(({ id }) => id === parcel.id)
      if (idx === -1) throw new Error('Invalid parcel to remove')
      parcels[idx].__detected = false
      parcels[idx].__forced = false
      RfidReader.removeTags(identifierCodesByParcel[parcel.id])
      delete identifierCodesByParcel[parcel.id]
      this.setState({ parcels, identifierCodesByParcel })
    } catch (error) {
      showToastError(error)
    }
  }

  openIgnoredEpcs = () => {
    const { ignoredEpcs } = this.state
    openModal({
      id: 'ignoredEpcs',
      modal: (
        <DecodedItemsModal
          decodedItems={ignoredEpcs.map((epc) => ({ identifierCode: epc }))}
          operation={this.operation}
          isOpen
          onClose={() => closeModal('ignoredEpcs')}
          title="Epcs"
        />
      ),
    })
  }

  openUnexpectedParcel = () => {
    const { unexpectedDecodedParcels } = this.state
    const parcels: ShippingParcel[] = []
    Object.keys(unexpectedDecodedParcels).forEach((key) => {
      const parcelCode = unexpectedDecodedParcels[key].parcelCode ?? 'UNKNOWN'
      const index = parcels.findIndex((p) => p.code === parcelCode)
      if (index >= 0) {
        parcels[index].parcelEntryQuantity++
      } else {
        parcels.push({
          code: parcelCode,
          parcelEntryQuantity: 1,
          attributes: {},
        } as any)
      }
    })
    openModal({
      id: 'unexpectedParcelsModal',
      modal: (
        <Modal
          title="Unexpected parcel"
          visible
          onClose={() => closeModal('unexpectedParcelsModal')}
          size="2xl"
          isCentered
        >
          <Box height="70vh">
            <GroupedParcelList operation={this.operation} parcels={parcels} />
          </Box>
        </Modal>
      ),
    })
  }

  render() {
    const { formData, loading, parcels, unexpectedDecodedParcels, ignoredEpcs } = this.state
    const unexpectedCount = Object.keys(unexpectedDecodedParcels).length
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            // setFormData: async (fd) => {
            //   if (!(await SimpleParcelListExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
            //   this.setState({ formData: fd })
            //   this.setRfidReaderDecode()
            // },
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={this.getDetected().length} expected={parcels.length - this.getUnexpected().length} />
            <AntennaButton
              onClear={this.onClear}
              hideClear={unexpectedCount === 0 && this.getDetected().length === 0}
            />
            <Spacer />

            {/* <OperationReadingCounters items={items} /> */}
            <Box row justify="space-evenly">
              {unexpectedCount > 0 && (
                <SmallTextCounter
                  onClick={this.openUnexpectedParcel}
                  status="warning"
                  title="Unexpected"
                  counter={unexpectedCount}
                />
              )}
              {unexpectedCount > 0 && ignoredEpcs.length > 0 && <Spacer />}
              {ignoredEpcs.length > 0 && (
                <SmallTextCounter
                  onClick={this.openIgnoredEpcs}
                  status="ignored"
                  title={__(T.misc.ignored)}
                  counter={ignoredEpcs.length}
                />
              )}
            </Box>
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <GroupedParcelList
            operation={this.operation}
            parcels={parcels}
            removeParcel={(parcel) => this.removeParcel(parcel)}
            refresh={() => this.forceUpdate()}
          />
        </Page.Content>
      </Page>
    )
  }
}
