import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, BoxProps } from 'stylewhere/components'

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.4;
  margin-bottom: 20px;
`

interface Props extends BoxProps {
  title?: string
}
export class Card extends Component<Props> {
  static Title = Title
  render() {
    const { children, title, ...rest } = this.props
    return (
      <CardContainer {...rest} title={title}>
        {title && <Title>{title}</Title>}
        {children}
      </CardContainer>
    )
  }
}

const CardContainer = styled(Box)<{ title?: string }>`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;

  ${({ title }) => title && `padding: 20px 20px;`}
`
