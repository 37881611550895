import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'

export function SmallTagCounter({
  unexpected = 0,
  detected = 0,
  expected = 0,
  onClick,
}: {
  unexpected?: number
  detected?: number
  expected?: number
  onClick?: () => void
}) {
  return (
    <SmallCounter onClick={onClick} unexpected={unexpected} detected={detected} expected={expected}>
      {`${detected}${expected > 0 ? `/${expected}` : ''}`}
    </SmallCounter>
  )
}

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0 20px;
  background-color: ${({ unexpected = 0, detected = 0, expected = 0 }) => {
    if (unexpected > 0) return '#F2B57D'
    if (expected === 0) return '#EDEDED'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    if (detected > expected) return '#F2B57D'
    return '#75EBA8'
  }};
`
