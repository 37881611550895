import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex: 1;
`

export const LeftContainer = styled.div`
  flex: 1.5;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 50px;
`

export const Placeholder = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: default;
  color: #828282;
`

export const PlaceholderError = styled(Placeholder)`
  width: 370px;
  color: red;
  font-weight: bold;
  border: 3px solid red;
  border-radius: 20px;
  padding: 60px;
`

export const Spacer = styled.div`
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
`

export const RowLine = styled.div<{ widthPerc?: number }>`
  width: calc(${(props) => props.widthPerc?.toString() || '100'}% - 2px);
  border-bottom: 1px solid #dfe1e5;
  box-shadow: none;
`
